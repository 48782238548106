//Node Modules
import { useSetRecoilState } from "recoil";
import { useTranslation } from "react-i18next";

//BinaryForge Components
import { BfDialog } from "../helpers";
import { Note } from "../helpers";

//3rd Party Components
import { Button } from "primereact/button";

//Atoms
import { dialogAtomFamily } from "../../atoms/dialog";

//Helpers

//Other

const FirmwareMd5Dialog = ({ md5Hash }) => {
	// Hooks
	const { t } = useTranslation();

	// Recoil
	const setShow = useSetRecoilState(dialogAtomFamily("firmwareMd5Dialog"));

	// Footer
	const footer = (
		<>
			<Button label={t("common.button.cancel")} onClick={() => setShow(false)} />
		</>
	);

	return (
		<BfDialog id="firmwareMd5Dialog" header={t("firmware.table.md5")} footer={footer}>
			<Note messageKey={md5Hash} messageStyle="font-large text-lineHeight-1" icon="pi pi-file font-success" />
		</BfDialog>
	);
};

export default FirmwareMd5Dialog;
