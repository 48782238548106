const nav = {
	guest: {
		notfound: "/not-found",
		login: "/login",
		logout: "/login",
		completeReg: "/completeRegistration",
		forgotPassword: "/forgotPassword",
		mfa: "/mfaChallenge",
		mobileApp: "/mobileApp",
	},
	combo: {
		verifyAttribute: "/verifyAttribute",
	},
	home: "/home",
	firmware: {
		base: "/firmware",
		configuration: "/firmware/configuration",
	},
	user: {
		base: "/user",
		profile: "/profile",
	},
	clinic: {
		base: "/clinic",
	},
	break: "/break",
};

const guestRoutes = Object.values(nav.guest);
const comboRoutes = Object.values(nav.combo);

const supportRoutes = [nav.home, nav.user.base + nav.user.profile, nav.user.base, nav.clinic.base, nav.break];
const technicianRoutes = [nav.home, nav.user.base + nav.user.profile, nav.firmware.configuration, nav.break];
const firmwareRoutes = [
	nav.home,
	nav.user.base + nav.user.profile,
	nav.firmware.base,
	nav.firmware.configuration,
	nav.break,
];

export { nav, guestRoutes, comboRoutes, supportRoutes, firmwareRoutes, technicianRoutes };
