//Node Modules
import React from "react";
import { useTranslation } from "react-i18next";

//Pages

//BinaryForge Components

//3rd Party Components

//Atoms

//Helpers

//Other

const AppFooter = () => {
	const { t } = useTranslation();

	return (
		<footer className="appFooter paddingTop-medium paddingBottom-medium">
			<div className="font-lighter font-small flexVert gap-small aItems-center appFooter-content">
				<div className="divider"></div>
				<div>
					{t("common.appName")} v{process.env.REACT_APP_VERSION} | &copy; {new Date().getFullYear()} {t("common.company")}
				</div>
			</div>
		</footer>
	);
};

export default AppFooter;
