//Node Modules
import React from "react";
import PageHelmet from "../helpers/PageHelmet";

//Pages

//BinaryForge Components

//3rd Party Components

//Atoms

//Helpers

//Other

const PageHeader = ({ title, subtitle, icon, actions }) => {
	return (
		<>
			<PageHelmet title={title} />
			<div className="pageHeader paddingTop-large paddingBottom-xlarge">
				<div className="flex jContent-spaceBetween aItems-end pageHeader-content">
					<div>
						<h1 className="pageTitle text-verticalAlt">{title}</h1>
						<div>
							<div className="pageTitle-icon">{icon}</div>
							<h2>{subtitle}</h2>
						</div>
					</div>
					<div className="flex gap-small">{actions}</div>
				</div>
			</div>
		</>
	);
};

export default PageHeader;
