//Node Modules
import { useState, useRef, useEffect } from "react";
import { useRecoilState } from "recoil";
import { useTranslation } from "react-i18next";

//Pages

//BinaryForge Components
import { DatatableItem } from "../helpers";

//3rd Party Components
import { FilterMatchMode, FilterOperator } from "primereact/api";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { Button } from "primereact/button";
import { MultiSelect } from "primereact/multiselect";
import { Calendar } from "primereact/calendar";
import { InputNumber } from "primereact/inputnumber";

//Atoms
import { selectedFirmwareAtom, firmwareListAtom } from "../../atoms/firmware";
import { getUiDateTime } from "../../helpers/DateUtils";

//Helpers

//Other
import { productRangeOptions, firmwareStatusOptions } from "../../config/firmware";

const FirmwareListTable = ({ firmwareData }) => {
	//Hooks
	const { t } = useTranslation();
	const dt = useRef(null);

	//Recoil
	const [selectedFirmware, setSelectedFirmware] = useRecoilState(selectedFirmwareAtom);
	const [firmwares, setFirmwares] = useRecoilState(firmwareListAtom);

	// Local State
	const [dtFilters, setDtFilters] = useState(null);

	const initFilters = () => {
		setDtFilters({
			name: {
				operator: FilterOperator.AND,
				constraints: [{ value: null, matchMode: FilterMatchMode.CONTAINS }],
			},
			filename: {
				operator: FilterOperator.AND,
				constraints: [{ value: null, matchMode: FilterMatchMode.CONTAINS }],
			},
			version: {
				operator: FilterOperator.AND,
				constraints: [{ value: null, matchMode: FilterMatchMode.EQUALS }],
			},
			product: { value: null, matchMode: FilterMatchMode.IN },
			uploadedAt: {
				operator: FilterOperator.AND,
				constraints: [{ value: null, matchMode: FilterMatchMode.DATE_IS }],
			},
			status: { value: null, matchMode: FilterMatchMode.IN },
		});
	};

	const filterApplyTemplate = (options) => {
		return (
			<Button
				type="button"
				label="Filter"
				icon="pi pi-check"
				onClick={options.filterApplyCallback}
				className="feature"
			/>
		);
	};

	const dateFilterTemplate = (options) => {
		return (
			<Calendar
				value={options.value}
				onChange={(e) => options.filterCallback(e.value, options.index)}
				placeholder={t("common.table.dateSelect")}
				dateFormat="dd M yy"
				readOnlyInput
				showButtonBar
			/>
		);
	};

	const versionFilterTemplate = (options) => {
		return (
			<InputNumber
				value={options.value}
				onChange={(e) => options.filterCallback(e.value, options.index)}
				mode="decimal"
				minFractionDigits={1}
				maxFractionDigits={3}
			/>
		);
	};

	const versionRowTemplate = (rowData) => {
		return Number(rowData.version).toFixed(2);
	};

	const productRangeRowTemplate = (rowData) => {
		return <span>{rowData.product.name}</span>;
	};

	const productRangeFilterTemplate = (option) => {
		return <span>{option.name}</span>;
	};

	const productRangeFilterSelectedTemplate = (option) => {
		if (option) {
			return <span>{option.name}</span>;
		}
	};

	const productRangeFilter = (options) => {
		return (
			<MultiSelect
				showClear
				value={options.value}
				options={productRangeOptions}
				itemTemplate={productRangeFilterTemplate}
				selectedItemTemplate={productRangeFilterSelectedTemplate}
				onChange={(e) => options.filterCallback(e.value)}
				placeholder={t("common.multiselectPlaceholder")}
			/>
		);
	};

	const statusRowTemplate = (rowData) => {
		return (
			<DatatableItem
				type="text"
				value={t(`firmware.status.${rowData.status}`)}
				icon={"pi-times-circle"}
				wrapperStyle={firmwareStatusOptions[rowData.status].colour}
			/>
		);
	};

	const statusFilterItemTemplate = (option) => {
		return (
			<DatatableItem
				type="text"
				value={t(`firmware.status.${option.value}`)}
				icon={"pi-times-circle"}
				wrapperStyle={firmwareStatusOptions[option.value].colour}
			/>
		);
	};

	const statusFilterSelectedTemplate = (option) => {
		if (option !== undefined) {
			return (
				<DatatableItem
					type="text"
					value={t(`firmware.status.${option}`)}
					icon={"pi-times-circle"}
					wrapperStyle={firmwareStatusOptions[option].colour}
				/>
			);
		}
	};

	const statusFilterTemplate = (options) => {
		return (
			<MultiSelect
				showClear
				value={options.value}
				options={firmwareStatusOptions}
				itemTemplate={statusFilterItemTemplate}
				selectedItemTemplate={statusFilterSelectedTemplate}
				onChange={(e) => options.filterCallback(e.value)}
				placeholder={t("common.form.multiselectPlaceholder")}
				scrollHeight="320px"
			/>
		);
	};

	const uploadedAtRowTemplate = (rowData) => {
		return <span>{getUiDateTime(rowData.uploadedAt)}</span>;
	};

	useEffect(() => {
		if (firmwareData) {
			const firmwareDataWithUpload = [];
			firmwareData.map((d) =>
				firmwareDataWithUpload.push({
					...d,
					uploadedAt: new Date(d.createdAt),
				})
			);
			setFirmwares(firmwareDataWithUpload);
			initFilters();
		}
	}, [firmwareData]);

	return (
		<DataTable
			ref={dt}
			value={firmwares}
			emptyMessage={t("common.table.noData")}
			selectionMode="single"
			selection={selectedFirmware}
			onSelectionChange={(e) => setSelectedFirmware(e.value)}
			sortMode="multiple"
			removableSort
			filters={dtFilters}
			filterDisplay="menu"
			autoLayout={true}
			paginator
			paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport"
			currentPageReportTemplate={t("common.table.paginatorTemplate")}
			rows={10}
			dataKey="id">
			<Column field="name" header={t("firmware.table.name")} sortable filter filterApply={filterApplyTemplate} />
			<Column
				field="filename"
				header={t("firmware.table.filename")}
				sortable
				filter
				filterApply={filterApplyTemplate}
			/>
			<Column
				field="version"
				header={t("firmware.table.version")}
				body={versionRowTemplate}
				dataType="numeric"
				sortable
				filter
				filterElement={versionFilterTemplate}
				filterApply={filterApplyTemplate}
			/>
			<Column
				field="product"
				header={t("firmware.table.product")}
				body={productRangeRowTemplate}
				sortable
				sortField="product.name"
				filter
				showFilterMatchModes={false}
				filterElement={productRangeFilter}
				filterApply={filterApplyTemplate}
			/>
			<Column
				field="uploadedAt"
				header={t("firmware.table.uploadedAt")}
				body={uploadedAtRowTemplate}
				dataType="date"
				sortable
				filter
				filterApply={filterApplyTemplate}
				filterElement={dateFilterTemplate}
			/>
			<Column
				field="status"
				header={t("firmware.table.status")}
				body={statusRowTemplate}
				sortable
				filter
				showFilterMatchModes={false}
				filterElement={statusFilterTemplate}
				filterApply={filterApplyTemplate}
			/>
		</DataTable>
	);
};

export default FirmwareListTable;
