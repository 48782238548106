import { useRecoilValueLoadable } from "recoil";
import { clinicCreatedBySelector } from "../../atoms/clinic";
import { Skeleton } from "primereact/skeleton";

const ClinicCreatedBy = () => {
	//Recoil
	const { state: clinicUserState, contents: clinicUserData } = useRecoilValueLoadable(clinicCreatedBySelector);

	switch (clinicUserState) {
		case "hasError":
			//TODO: Show a proper error component here
			return <div>{clinicUserData.message ? clinicUserData.message : clinicUserData}</div>;
		case "loading":
			return <Skeleton width="75%" />;
		case "hasValue":
			return <span>{clinicUserData.fullname}</span>;
		default:
			return null;
	}
};

export default ClinicCreatedBy;
