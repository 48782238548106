//Node Modules
import { useEffect } from "react";
import { useRecoilState, useSetRecoilState } from "recoil";
import { useTranslation } from "react-i18next";
import { useForm, Controller } from "react-hook-form";
import _ from "lodash";

//BinaryForge Components

//3rd Party Components
import { InputText } from "primereact/inputtext";
import { classNames } from "primereact/utils";

//Atoms
import { dialogAtomFamily } from "../../atoms/dialog";
import { selectedClinicAtom, clinicListAtom } from "../../atoms/clinic";
import { triggerSubmitAtom } from "../../atoms/form";
import { toastAtom } from "../../atoms/toast";

//Helpers
import { useApiRequest } from "../../helpers/Api";

//Other

const ClinicForm = ({ type }) => {
	// Hooks
	const { t } = useTranslation();
	const apiRequest = useApiRequest();

	// Recoil
	const setShow = useSetRecoilState(dialogAtomFamily(`${type}Dialog`));
	const [toast, setToast] = useRecoilState(toastAtom);
	const [triggerSubmit, setTriggerSubmit] = useRecoilState(triggerSubmitAtom);
	const setClinics = useSetRecoilState(clinicListAtom);
	const [selectedClinic, setSelectedClinic] = useRecoilState(selectedClinicAtom);

	// Form Default Values
	const defaultValues = {
		name: "",
		buildingNumber: "",
		street: "",
		city: "",
		postcode: "",
		county: "",
		country: "",
	};

	// Form Init
	const {
		control,
		formState: { errors },
		handleSubmit,
		reset,
	} = useForm({ defaultValues: defaultValues, mode: "onTouched", reValidateMode: "onChange" });

	// Form Error Message
	const getFormErrorMessage = (name) => {
		return errors[name] && <span className="font-error font-small">{errors[name].message}</span>;
	};

	// Form Handle Submit
	useEffect(() => {
		if (triggerSubmit) {
			handleSubmit(onSubmit)();
			setTriggerSubmit(false);
		}
	}, [triggerSubmit]);

	// Fetch the clinic if one is available
	useEffect(() => {
		if (type === "clinicEdit" && selectedClinic)
			reset({
				name: selectedClinic.name,
				buildingNumber: selectedClinic.buildingNumber,
				street: selectedClinic.street,
				city: selectedClinic.city,
				postcode: selectedClinic.postcode,
				county: selectedClinic.county,
				country: selectedClinic.country,
			});
	}, [type, selectedClinic]);

	const onSubmit = async (data) => {
		try {
			const apiMethod = type === "clinicCreate" ? "post" : "put";
			const apiEndpoint = type === "clinicCreate" ? "/clinic" : `/clinic/${selectedClinic.id}`;
			const clinic = await apiRequest(apiMethod, apiEndpoint, data, t(`clinic.${type}.loading`));

			setClinics((prevState) => _.unionBy([clinic], prevState, "id"));
			type === "clinicEdit" && setSelectedClinic(clinic);

			setToast({
				...toast,
				severity: "success",
				summary: t(`clinic.${type}.successSummary`),
				detail: t(`clinic.${type}.successDetail`),
			});
			setShow(false);
		} catch (err) {
			setToast({
				...toast,
				severity: "error",
				summary: t(`clinic.${type}.errorSummary`),
				detail: t(`clinic.${type}.errorDetail`, { error: err.response.data.message }),
			});
		}
	};

	return (
		<form>
			<div className="formField">
				<label htmlFor="name">{t("clinic.clinicForm.name.label")}</label>
				<Controller
					name="name"
					control={control}
					rules={{
						required: t("common.form.required"),
					}}
					render={({ field, fieldState }) => (
						<InputText
							id={field.name}
							{...field}
							// readOnly={type === "clinicCreate" ? false : true}
							className={classNames({ "p-error": fieldState.error })}
						/>
					)}
				/>
				{getFormErrorMessage("name")}
			</div>

			<div className="grid columns-2 gap-medium">
				<div className="formField">
					<label htmlFor="buildingNumber">{t("clinic.clinicForm.buildingNumber.label")}</label>
					<Controller
						name="buildingNumber"
						control={control}
						rules={{
							required: t("common.form.required"),
						}}
						render={({ field, fieldState }) => (
							<InputText
								id={field.name}
								{...field}
								className={classNames({ "p-error": fieldState.error })}
							/>
						)}
					/>
					{getFormErrorMessage("buildingNumber")}
				</div>

				<div className="formField">
					<label htmlFor="street">{t("clinic.clinicForm.street.label")}</label>
					<Controller
						name="street"
						control={control}
						rules={{
							required: t("common.form.required"),
						}}
						render={({ field, fieldState }) => (
							<InputText
								id={field.name}
								{...field}
								className={classNames({ "p-error": fieldState.error })}
							/>
						)}
					/>
					{getFormErrorMessage("street")}
				</div>

				<div className="formField">
					<label htmlFor="city">{t("clinic.clinicForm.city.label")}</label>
					<Controller
						name="city"
						control={control}
						rules={{
							required: t("common.form.required"),
						}}
						render={({ field, fieldState }) => (
							<InputText
								id={field.name}
								{...field}
								className={classNames({ "p-error": fieldState.error })}
							/>
						)}
					/>
					{getFormErrorMessage("city")}
				</div>

				<div className="formField">
					<label htmlFor="postcode">{t("clinic.clinicForm.postcode.label")}</label>
					<Controller
						name="postcode"
						control={control}
						rules={{
							required: t("common.form.required"),
						}}
						render={({ field, fieldState }) => (
							<InputText
								id={field.name}
								{...field}
								className={classNames({ "p-error": fieldState.error })}
							/>
						)}
					/>
					{getFormErrorMessage("postcode")}
				</div>

				<div className="formField">
					<label htmlFor="county">{t("clinic.clinicForm.county.label")}</label>
					<Controller
						name="county"
						control={control}
						rules={{
							required: t("common.form.required"),
						}}
						render={({ field, fieldState }) => (
							<InputText
								id={field.name}
								{...field}
								className={classNames({ "p-error": fieldState.error })}
							/>
						)}
					/>
					{getFormErrorMessage("county")}
				</div>

				<div className="formField">
					<label htmlFor="country">{t("clinic.clinicForm.country.label")}</label>
					<Controller
						name="country"
						control={control}
						rules={{
							required: t("common.form.required"),
						}}
						render={({ field, fieldState }) => (
							<InputText
								id={field.name}
								{...field}
								className={classNames({ "p-error": fieldState.error })}
							/>
						)}
					/>
					{getFormErrorMessage("country")}
				</div>
			</div>
		</form>
	);
};

export default ClinicForm;
