//Node Modules
import React from "react";
import { useRecoilState } from "recoil";

//3rd Party Components
import { Dialog } from "primereact/dialog";

//Atoms
import { dialogAtomFamily } from "../../atoms/dialog";

function DialogCustom({ header, footer, id, children, dialogClass }) {
	const appElement = document.getElementsByClassName("appWrapper")[0];

	const [show, setShow] = useRecoilState(dialogAtomFamily(id));

	return (
		<Dialog
			header={header}
			footer={footer}
			visible={show}
			closable={true}
			onHide={() => setShow(false)}
			appendTo={appElement}
			maskClassName="mask"
			className={dialogClass}>
			{children}
		</Dialog>
	);
}

export default DialogCustom;
