//Node Modules
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

//Pages

//BinaryForge Components
import { PageHeader } from "../../../components/layout";
import { UserProfileAttributes, UserProfileMFA, UserProfilePassword } from "../../../components/user";

//3rd Party Components

//Atoms

//Helpers
import { useGetCurrentCognitoUser } from "../../../helpers/Auth";

//Other

const Profile = () => {
	//Hooks
	const { t } = useTranslation();
	const getCognitoUser = useGetCurrentCognitoUser();

	// Local State
	const [currentUser, setCurrentUser] = useState();

	useEffect(() => {
		const getCurrentUser = async () => {
			const cognitoUser = await getCognitoUser();
			setCurrentUser(cognitoUser);
		};

		getCurrentUser();
	}, []);

	return (
		currentUser && (
			<>
				<PageHeader
					title={t("profile.pageTitle")}
					subtitle={t("profile.pageSubtitle", { username: currentUser.username })}
					icon={<i className="pi pi-user" />}
				/>
				<main className="appMain">
					<div className="appMain-content">
						<UserProfileAttributes currentUser={currentUser} />

						<UserProfilePassword currentUser={currentUser} />
						<UserProfileMFA currentUser={currentUser} />
					</div>
				</main>
			</>
		)
	);
};

export default Profile;
