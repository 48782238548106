const Note = ({ icon, messageKey, messageStyle, wrapperStyle, action }) => {
	return (
		<div className={`flex gap-small ${wrapperStyle}`} onClick={() => action()}>
			<i className={`pi font-large ${icon}`} />
			<p className={messageStyle}>{messageKey}</p>
		</div>
	);
};

export default Note;
