//Node Modules
import { useRecoilValueLoadable, useSetRecoilState } from "recoil";
import { useTranslation } from "react-i18next";

//Pages

//BinaryForge Components

//3rd Party Components
import { Button } from "primereact/button";

//Atoms
import { selectedClinicSelector } from "../../atoms/clinic";
import { dialogAtomFamily } from "../../atoms/dialog";

//Helpers
import { getUiDateTime } from "../../helpers/DateUtils";
import ClinicCreatedBy from "./ClinicCreatedBy";

//Other

const ClinicDetails = () => {
	//Hooks
	const { t } = useTranslation();

	//Recoil
	const { state: selectedClinicState, contents: selectedClinicData } = useRecoilValueLoadable(selectedClinicSelector);
	const setShowEditClinic = useSetRecoilState(dialogAtomFamily("clinicEditDialog"));

	switch (selectedClinicState) {
		case "hasError":
			//TODO: Show a proper error component here
			return <div>{selectedClinicData.message ? selectedClinicData.message : selectedClinicData}</div>;
		case "loading":
			return <div>LOADING</div>;
		case "hasValue":
			return (
				<section>
					<div className="card">
						<div className="flex jContent-spaceBetween marginBottom-large">
							<div className="font-large">
								<span className="font-bold">{t("clinic.table.name")}: </span>
								<span>{selectedClinicData.name}</span>
							</div>
							<div className="flex gap-small jContent-end">
								<Button
									onClick={() => setShowEditClinic(true)}
									label={t("clinic.actions.update")}
									disabled={!selectedClinicData}
									className="feature"
								/>
							</div>
						</div>
						<div>
							<div className="grid columns-3">
								<div className="grid columns-label gapCol-medium gapRow-xsmall aItems-center aContent-start">
									<span>{t("clinic.table.building")}:</span>
									<span>{selectedClinicData.buildingNumber}</span>
									<span>{t("clinic.table.street")}:</span>
									<span>{selectedClinicData.street}</span>
									<span>{t("clinic.table.city")}:</span>
									<span>{selectedClinicData.city}</span>
								</div>
								<div className="grid columns-label gapCol-medium gapRow-xsmall aItems-center aContent-start">
									<span>{t("clinic.table.postcode")}:</span>
									<span>{selectedClinicData.postcode}</span>
									<span>{t("clinic.table.county")}:</span>
									<span>{selectedClinicData.county}</span>
									<span>{t("clinic.table.country")}:</span>
									<span>{selectedClinicData.country}</span>
								</div>
								<div className="grid columns-label gapCol-medium gapRow-xsmall aItems-center aContent-start">
									<span>{t("common.table.createdAt")}:</span>
									<span>{getUiDateTime(selectedClinicData.createdAt)}</span>
									<span>{t("common.table.createdBy")}:</span>
									<ClinicCreatedBy />
								</div>
							</div>
						</div>
					</div>
				</section>
			);
		default:
			return null;
	}
};

export default ClinicDetails;
