//Node Modules
import React from "react";
import { useSetRecoilState } from "recoil";
import { useTranslation } from "react-i18next";

//BinaryForge Components
import { BfDialog, Note } from "../helpers";
import { UserForm } from "./";

//3rd Party Components
import { Button } from "primereact/button";

//Atoms
import { dialogAtomFamily } from "../../atoms/dialog";
import { triggerSubmitAtom } from "../../atoms/form";

const UserCreateDialog = () => {
	// Hooks
	const { t } = useTranslation();

	// Recoil
	const setShow = useSetRecoilState(dialogAtomFamily("userCreateDialog"));
	const setTriggerSubmit = useSetRecoilState(triggerSubmitAtom);

	// Footer
	const footer = (
		<>
			<Button label={t("common.button.cancel")} onClick={() => setShow(false)} />
			<Button
				label={t("common.button.save")}
				icon="pi pi-save"
				className="feature"
				// disabled={!isValid}
				onClick={() => setTriggerSubmit(true)}
			/>
		</>
	);

	return (
		<BfDialog id="userCreateDialog" header={t("user.dialog.create.title")} footer={footer}>
			<UserForm type="userCreate" />
			<Note
				wrapperStyle="marginTop-medium"
				icon="pi pi-info-circle font-info"
				messageKey={t("user.userCreate.passwordNote")}
				messageStyle="font-light"
			/>
		</BfDialog>
	);
};

export default UserCreateDialog;
