//Node Modules
import React, { useEffect, useRef } from "react";
import { useRecoilValue, useResetRecoilState } from "recoil";
import { useLocation } from "react-router";

//Pages

//BinaryForge Components
import { AppHeader, AppFooter } from "./components/layout";
import { NavRoutes } from "./components/helpers";

//3rd Party Components
import { Toast } from "primereact/toast";
import { ConfirmDialog } from "primereact/confirmdialog";

//Atoms
import { themeAtom } from "./atoms/theme";
import { toastAtom } from "./atoms/toast";

//Helpers

//Messages & Languages
import "./i18n";

//Nav
import { comboRoutes, guestRoutes } from "./config/navigation";

//Styles
import "primereact/resources/primereact.min.css";
import "primeicons/primeicons.css";
import "./assets/css/main.css";
import Loading from "./components/helpers/LoadingDialog";

function App() {
	//Hooks & Util
	const location = useLocation();
	const toastRef = useRef(null);
	const pageType = guestRoutes.includes(location.pathname)
		? "guest"
		: comboRoutes.includes(location.pathname)
		? "combo"
		: "auth";

	//Recoil
	const theme = useRecoilValue(themeAtom);
	const toast = useRecoilValue(toastAtom);
	const resetToast = useResetRecoilState(toastAtom);

	//Make sure the correct theme is set
	useEffect(() => {
		document.body.setAttribute("data-theme", theme);
	}, [theme]);

	//Generic show toast message
	useEffect(() => {
		if (toast.detail != null || toast.length > 1) {
			toastRef.current.show(toast);
		}
		resetToast();
	}, [toast]);

	return (
		<div className={`appWrapper ${pageType}`}>
			<Toast ref={toastRef} />

			{pageType === "auth" && <AppHeader />}

			<NavRoutes />
			<ConfirmDialog />
			<Loading />
			<AppFooter />
		</div>
	);
}

export default App;
