//Node Modules
import React from "react";
import { Link } from "react-router-dom";

//Pages

//BinaryForge Components
import AppNav from "./AppNav";

//3rd Party Components

//Atoms

//Helpers

//Other
import { ReactComponent as SteeperLogo } from "../../assets/img/steeper-logo-en-GB.svg";

const AppHeader = () => {
	return (
		<header className="appHeader">
			<div className="grid columnsAuto-2 jContent-spaceBetween aItems-center appHeader-content">
				<div className="logo-wrapper">
					<Link to="/home">
						<SteeperLogo className="logo" />
					</Link>
				</div>
				<div>
					<AppNav />
				</div>
			</div>
		</header>
	);
};

export default AppHeader;
