import React from "react";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import { AppFooter } from "../layout";
import logo from "../../assets/img/steeper-logo-en-GB.svg";

const ErrorBoundary = () => {
	const { t } = useTranslation();

	return (
		<div className="appWrapper appErrorWrapper">
			<main className="appMain">
				<div className="appMain-content">
					<div className="flexVert iItems-center marginBottom-medium">
						<img src={logo} alt="Logo" className="logo marginBottom-small" />
						<div className="appName text-center">
							<span className="font-medium font-lighter text-uppercase">{t("common.appName")}</span>
						</div>
					</div>
					<h1>Something went wrong!</h1>
					<div>
						<p>
							Unfortunately there has been an unexpected error; our team have been made aware of the
							error.
						</p>
						<p>If you continue to experience an error please contact support directly.</p>
					</div>
					<div className="flexVert gap-xsmall">
						<Link to={0}>Back to previous page</Link>
						<Link to="/home" reloadDocument>
							Go to home page
						</Link>
					</div>
				</div>
			</main>
			<AppFooter />
		</div>
	);
};

export default ErrorBoundary;
