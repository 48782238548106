//Node Modules
import { useEffect, useState } from "react";
import { Navigate, useLocation } from "react-router-dom";
import { useRecoilValue } from "recoil";

//Helpers
import { useIsValidSession } from "../../helpers/Auth";

//Atoms
import { loggedInUserAtom } from "../../atoms/user";

//Other
import { nav, supportRoutes, firmwareRoutes, technicianRoutes } from "../../config/navigation";

const AuthRoute = ({ children }) => {
	const location = useLocation();
	const isValidSession = useIsValidSession();

	//Recoil State
	const user = useRecoilValue(loggedInUserAtom);

	//Local State
	const [isValid, setIsValid] = useState();

	useEffect(() => {
		const validSession = async () => {
			setIsValid((await isValidSession()) ? true : false);
		};

		validSession();
	}, [children, isValidSession]);

	const validRole = () => {
		let valid = false;
		if (user.roles.includes("support") && supportRoutes.includes(location.pathname)) valid = true;
		if (user.roles.includes("firmware") && firmwareRoutes.includes(location.pathname)) valid = true;
		if (user.roles.includes("technician") && technicianRoutes.includes(location.pathname)) valid = true;

		if (valid) {
			return children;
		} else {
			return <Navigate to={nav.home} replace />;
		}
	};

	if (isValid === undefined) return null;

	return isValid ? validRole() : <Navigate to={nav.guest.login} state={{ from: location }} replace />;
};

export default AuthRoute;
