// import { API } from "aws-amplify";
import { API } from "aws-amplify";
import { atom, selector } from "recoil";

const apiName = "steeperApi";

export const clinicListAtom = atom({
	key: "clinicListAtom",
	default: null,
});

export const clinicOptionsAtom = atom({
	key: "clinicOptionsAtom",
	default: null,
});

export const clinicListSelector = selector({
	key: "clinicListSelector",
	get: async () => {
		const clinics = await API.get(apiName, "/clinic");
		return clinics;
	},
	set: ({ set }, newValue) => {
		set(clinicListAtom, newValue);
	},
});

export const selectedClinicAtom = atom({
	key: "selectedClinicAtom",
	default: null,
});

export const selectedClinicSelector = selector({
	key: "selectedClinicSelector",
	get: async ({ get }) => {
		let clinic = null;
		const selectedClinic = get(selectedClinicAtom);
		if (selectedClinic) {
			clinic = await API.get(apiName, `/clinic/${selectedClinic.id}`);
		}
		return clinic;
	},
});

export const clinicCreatedBySelector = selector({
	key: "clinicCreatedBySelector",
	get: async ({ get }) => {
		const clinic = get(selectedClinicAtom);
		const users = await API.get(apiName, `/user/byAttribute?name=sub&type=%3D&value=${clinic.createdBy}`);
		return users.length ? users[0] : { fullname: clinic.createdBy };
	},
});
