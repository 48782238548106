//Node Modules
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useForm, Controller } from "react-hook-form";
import { Link, useNavigate, useSearchParams } from "react-router-dom";
import { Auth } from "aws-amplify";
import { useRecoilState } from "recoil";

//Pages

//BinaryForge Components
import logo from "../../assets/img/steeper-logo-en-GB.svg";

//3rd Party Components
import { Steps } from "primereact/steps";
import { Button } from "primereact/button";
import { InputText } from "primereact/inputtext";
import { Password } from "primereact/password";
import { classNames } from "primereact/utils";
import { Tooltip } from "primereact/tooltip";

//Atoms
import { toastAtom } from "../../atoms/toast";
// import { loadingDialogAtom } from "../../atoms/DialogAtom";

//Helpers
import { passwordValidation, usernameValidation } from "../../config/user";

//Other
import { nav } from "../../config/navigation";

const ForgotPassword = () => {
	const { t } = useTranslation();
	const navigate = useNavigate();
	const [searchParams] = useSearchParams();

	const steps = [{ label: t("forgotPassword.request") }, { label: t("forgotPassword.reset") }];

	//Recoil State
	const [toast, setToast] = useRecoilState(toastAtom);
	// const setLoading = useSetRecoilState(loadingDialogAtom);

	//LocalState
	const [step, setStep] = useState(0);
	const [user, setUser] = useState();

	//Check if we need to go straight to step 2
	useEffect(() => {
		if (searchParams.get("stage") === "2") setStep(1);
	}, [searchParams]);

	//React Hook Form - Code
	const defaultValues = {
		username: "",
	};

	const {
		control,
		formState: { errors },
		handleSubmit,
	} = useForm({ defaultValues, mode: "onTouched", reValidateMode: "onChange" });

	// Form Error Message
	const getFormErrorMessage = (name) => {
		return errors[name] && <span className="font-error font-small">{errors[name].message}</span>;
	};

	const onSubmitRequestCode = async (data) => {
		const lazyUsername = data.username.trim(); //Bodge fix because copy and paste username can include whitespace - I don't like this.

		try {
			await Auth.forgotPassword(lazyUsername);
			setUser(lazyUsername);
			setStep(1);
			setToast({
				...toast,
				severity: "info",
				summary: t("forgotPassword.toast.codeSentSummary"),
				detail: t("forgotPassword.toast.codeSentDetail"),
			});
		} catch (error) {
			console.error("Password Reset Code Error:", error);
			setToast({
				...toast,
				severity: "error",
				summary: t("forgotPassword.toast.codeErrorSummary"),
				detail: t("forgotPassword.toast.codeErrorDetail", { err: error.message }),
			});
		}
	};

	//React Hook Form - Reset
	const defaultValuesReset = {
		username: "",
		code: "",
		password: "",
		passwordConfirm: "",
	};

	const {
		control: controlReset,
		formState: { errors: errorsReset },
		handleSubmit: handleSubmitReset,
		getValues: getValuesReset,
	} = useForm({ defaultValuesReset, mode: "onTouched", reValidateMode: "onChange" });

	// Form Error Message
	const getFormErrorMessageReset = (name) => {
		return errorsReset[name] && <span className="font-error font-small">{errorsReset[name].message}</span>;
	};

	const onSubmitForgotPassword = async (data) => {
		try {
			const username = user ? user : data.username.trim();
			await Auth.forgotPasswordSubmit(username, data.code, data.password);
			setToast({
				...toast,
				severity: "success",
				summary: t("forgotPassword.toast.resetSuccessSummary"),
				detail: t("forgotPassword.toast.resetSuccessDetail"),
			});
			navigate(nav.guest.login);
		} catch (error) {
			console.error("Password Reset Error:", error);
			if (!user && error.message.startsWith("Invalid code")) {
				setToast({
					...toast,
					severity: "error",
					summary: t("forgotPassword.toast.resetErrorSummary"),
					detail: t("forgotPassword.toast.resetErrorCustom"),
				});
			} else {
				setToast({
					...toast,
					severity: "error",
					summary: t("forgotPassword.toast.resetErrorSummary"),
					detail: t("forgotPassword.toast.resetErrorDetail", {
						err: error.message,
					}),
				});
			}
		}
	};

	return (
		<main className="appMain">
			<div className="appMain-content">
				<img src={logo} alt="Logo" className="logo marginBottomSmall" />
				<div className="appName marginBottom-xlarge text-center">
					<span className="font-large font-lighter text-uppercase">{t("common.appName")}</span>
				</div>

				<Steps className="marginBottom-medium" model={steps} activeIndex={step} />

				{step === 0 && (
					<form onSubmit={handleSubmit(onSubmitRequestCode)}>
						<div className="formField">
							<label htmlFor="username">{t("user.userForm.username.label")}</label>
							<Controller
								name="username"
								control={control}
								rules={{
									required: t("common.form.required"),
									pattern: {
										value: usernameValidation,
										message: t("user.userForm.username.pattern"),
									},
								}}
								render={({ field, fieldState }) => (
									<InputText
										id={field.name}
										{...field}
										className={classNames({ "p-error": fieldState.error })}
									/>
								)}
							/>
							{getFormErrorMessage("username")}
						</div>
						<Button type="submit" label={t("forgotPassword.request")} className="feature" />
					</form>
				)}
				{step === 1 && (
					<form onSubmit={handleSubmitReset(onSubmitForgotPassword)}>
						<Tooltip target=".customTooltip" />
						{!user && (
							<div className="formField">
								<label htmlFor="username">{t("user.userForm.username.label")}</label>
								<Controller
									name="username"
									control={controlReset}
									rules={{
										required: t("common.form.required"),
										pattern: {
											value: usernameValidation,
											message: t("user.userForm.username.pattern"),
										},
									}}
									render={({ field, fieldState }) => (
										<InputText
											id={field.name}
											{...field}
											className={classNames({ "p-error": fieldState.error })}
										/>
									)}
								/>
								{getFormErrorMessageReset("username")}
							</div>
						)}
						<div className="formField">
							<div className="flex jContent-spaceBetween">
								<label htmlFor="code">{t("forgotPassword.form.code.label")}</label>
								<i
									className="pi pi-info-circle font-info customTooltip"
									data-pr-tooltip={t("forgotPassword.form.code.fail")}
								/>
							</div>
							<Controller
								name="code"
								control={controlReset}
								rules={{
									required: t("common.form.required"),
								}}
								render={({ field, fieldState }) => (
									<InputText
										id={field.name}
										{...field}
										className={classNames({ "p-error": fieldState.error })}
									/>
								)}
							/>
							{getFormErrorMessageReset("code")}
						</div>
						<div className="formField">
							<label htmlFor="password">{t("forgotPassword.form.password.label")}</label>
							<Controller
								name="password"
								control={controlReset}
								rules={{
									required: t("common.form.required"),
									pattern: {
										value: passwordValidation,
										message: t("common.form.password"),
									},
								}}
								render={({ field: { ref, ...newField }, fieldState }) => (
									<Password
										{...newField}
										inputRef={ref}
										className={classNames({ "p-error": fieldState.error })}
										feedback={false}
										toggleMask={true}
									/>
								)}
							/>
							{getFormErrorMessageReset("password")}
						</div>
						<div className="formField">
							<label htmlFor="passwordConfirm">{t("forgotPassword.form.passwordConfirm.label")}</label>
							<Controller
								name="passwordConfirm"
								control={controlReset}
								rules={{
									required: t("common.form.required"),
									validate: {
										match: (value) =>
											getValuesReset("password") === value ||
											t("forgotPassword.form.passwordConfirm.match"),
									},
								}}
								render={({ field: { ref, ...newField }, fieldState }) => (
									<Password
										{...newField}
										inputRef={ref}
										className={classNames({ "p-error": fieldState.error })}
										feedback={false}
										toggleMask={true}
									/>
								)}
							/>
							{getFormErrorMessageReset("passwordConfirm")}
						</div>
						<Button type="submit" label="Reset Password" className="feature" />
					</form>
				)}
				<div className="marginTop-small text-center">
					<Link to={nav.guest.login} className="font-small">
						{t("forgotPassword.back")}
					</Link>
				</div>
			</div>
		</main>
	);
};

export default ForgotPassword;
