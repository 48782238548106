import { useTranslation } from "react-i18next";
import { Button } from "primereact/button";
import { getUiDate } from "../../helpers/DateUtils";
import { getMD5 } from "../../helpers/Firmware";

const ItemTemplate = ({ file, uploadProps, icon }) => {
	const { t } = useTranslation();

	return (
		<div className="flex aItems-center jContent-spaceBetween">
			<div className="flex gap-small aItems-center">
				<span className="font-light font-large font-success">{icon}</span>
				<span>{file.name}</span>
				<div className="flexVert marginLeft-medium">
					<div className="grid columnsAuto-2 gapCol-small font-small font-light text-left">
						<span>{t("firmware.table.filesize")}:</span>
						<span>{file.size} kb</span>
						<span>{t("common.modified")}:</span>
						<span className="font-small font-light">{getUiDate(file.lastModified)}</span>
						<span>{t("firmware.table.md5")}:</span>
						<span className="font-small font-light">{getMD5(file)}</span>
					</div>
				</div>
			</div>
			<Button type="button" icon="pi pi-times" className="rounded error" onClick={() => uploadProps.onRemove()} />
		</div>
	);
};

const EmptyTemplate = ({ icon, message }) => {
	return (
		<div className="flex gap-small aItems-center">
			<span className="font-light font-large">{icon}</span>
			<span className="font-light">{message}</span>
		</div>
	);
};

export { ItemTemplate, EmptyTemplate };
