//Node Modules
import { useRecoilValueLoadable } from "recoil";
import { useTranslation } from "react-i18next";

//Pages

//BinaryForge Components
import { FirmwareListTable } from "./";
import { Note } from "../helpers";

//3rd Party Components

//Atoms
import { firmwareListSelector } from "../../atoms/firmware";
import { DatatableSkeleton } from "../helpers/skeleton";

//Helpers

//Other

const FirmwareList = () => {
	//Recoil
	const { t } = useTranslation();
	const { state, contents } = useRecoilValueLoadable(firmwareListSelector);

	switch (state) {
		case "hasError":
			return (
				<Note
					messageKey={`Error: ${contents.message ? contents.message : contents}`}
					icon="pi-exclamation-circle font-error"
				/>
			);
		case "loading":
			return (
				<DatatableSkeleton
					columns={[
						t("firmware.table.name"),
						t("firmware.table.filename"),
						t("firmware.table.version"),
						t("firmware.table.product"),
						t("firmware.table.uploadedAt"),
						t("firmware.table.status"),
					]}
				/>
			);
		case "hasValue":
			return <FirmwareListTable firmwareData={contents} />;
		default:
			return null;
	}
};

export default FirmwareList;
