import { useTranslation } from "react-i18next";
import QRCode from "react-qr-code";
import { Copy, Or } from "../helpers";

const Code = ({ value, json }) => {
	const { t } = useTranslation();

	return (
		<div className="flexVert aItems-center">
			<p className="marginBottom-xsmall">{t("configuration.code.scan")}</p>
			<div className="codeWrapper">
				<QRCode
					value={json ? JSON.stringify(value) : value}
					fgColor="#942b5c"
					style={{ height: "auto", maxWidth: "100%", width: "100%" }}
					viewBox={`0 0 256 256`}
				/>
			</div>
			<Or orientation="horiz" />
			<p className="marginBottom-xsmall">{t("configuration.code.copy")}</p>
			<Copy message={value} json />
		</div>
	);
};

export default Code;
