//Node Modules
import React from "react";
import { useTranslation } from "react-i18next";
import { useForm, Controller } from "react-hook-form";
import { Auth } from "aws-amplify";
import { useRecoilState, useSetRecoilState } from "recoil";

//Pages

//BinaryForge Components

//3rd Party Components
import { Button } from "primereact/button";
import { Password } from "primereact/password";
import { classNames } from "primereact/utils";

//Atoms
import { toastAtom } from "../../atoms/toast";
import { dialogAtomFamily } from "../../atoms/dialog";

//Helpers

//Other
import { passwordValidation } from "../../config/user";

const UserProfilePassword = ({ currentUser }) => {
	const { t } = useTranslation();

	//Recoil State
	const [toast, setToast] = useRecoilState(toastAtom);
	const setLoading = useSetRecoilState(dialogAtomFamily("loadingDialog"));

	//React Hook Form
	const defaultValues = {
		password: "",
		passwordNew: "",
		passwordConfirm: "",
	};
	const {
		control,
		getValues,
		formState: { errors },
		handleSubmit,
	} = useForm({
		defaultValues,
		mode: "onTouched",
		reValidateMode: "onChange",
	});

	// Form Error Message
	const getFormErrorMessage = (name) => {
		return errors[name] && <span className="font-error font-small">{errors[name].message}</span>;
	};

	// Update Password
	const handleUpdatePassword = async (data) => {
		try {
			setLoading({ visible: true, message: t("profile.attributes.loading") });
			await Auth.changePassword(currentUser, data.password, data.passwordNew);
			setToast({
				...toast,
				severity: "success",
				summary: t("profile.password.toast.successSummary"),
				detail: t("profile.password.toast.successDetail"),
			});
		} catch (err) {
			console.error("Update User Attr Error ::", err);
			setToast({
				...toast,
				severity: "error",
				summary: t("profile.password.toast.errorSummary"),
				detail: t("profile.password.toast.errorDetail", { error: err.message }),
			});
		} finally {
			setLoading({ visible: false });
		}
	};

	return (
		<section>
			<div className="card">
				<header>
					<h3>{t("profile.password.title")}</h3>
				</header>
				<form onSubmit={handleSubmit(handleUpdatePassword)}>
					<div className="grid columns-2 gap-large">
						<div className="formField">
							<label htmlFor="password">{t("profile.password.current")}</label>
							<Controller
								name="password"
								control={control}
								rules={{
									required: t("common.form.required"),
									pattern: {
										value: passwordValidation,
										message: t("common.form.password"),
									},
								}}
								render={({ field, fieldState }) => (
									<Password
										id={field.password}
										{...field}
										feedback={false}
										toggleMask={true}
										className={classNames({ "p-error": fieldState.error })}
									/>
								)}
							/>
							{getFormErrorMessage("password")}
						</div>
						<div className="flexVert gap-medium">
							<div className="formField">
								<label htmlFor="passwordNew">{t("login.passwordNew.label")}</label>
								<Controller
									name="passwordNew"
									control={control}
									rules={{
										required: t("common.form.required"),
										pattern: {
											value: passwordValidation,
											message: t("common.form.password"),
										},
										validate: {
											match: (value) =>
												getValues("passwordCurrent") !== value || t("login.passwordNew.match"),
										},
									}}
									render={({ field, fieldState }) => (
										<Password
											id={field.passwordNew}
											{...field}
											feedback={false}
											toggleMask={true}
											className={classNames({ "p-error": fieldState.error })}
										/>
									)}
								/>
								{getFormErrorMessage("passwordNew")}
							</div>
							<div className="formField">
								<label htmlFor="passwordConfirm">{t("login.passwordConfirm.label")}</label>
								<Controller
									name="passwordConfirm"
									control={control}
									rules={{
										required: t("common.form.required"),
										validate: {
											match: (value) =>
												getValues("passwordNew") === value || t("login.passwordConfirm.match"),
										},
									}}
									render={({ field, fieldState }) => (
										<Password
											id={field.passwordConfirm}
											{...field}
											feedback={false}
											toggleMask={true}
											className={classNames({ "p-error": fieldState.error })}
										/>
									)}
								/>
								{getFormErrorMessage("passwordConfirm")}
							</div>
						</div>
					</div>
					<Button type="submit" label={t("profile.password.submit")} className="feature aSelf-start" />
				</form>
			</div>
		</section>
	);
};

export default UserProfilePassword;
