//Node Modules
import { useNavigate } from "react-router-dom";
import { Auth } from "aws-amplify";
import { useRecoilState, useResetRecoilState } from "recoil";
import { useTranslation } from "react-i18next";

//Atoms
import { loggedInUserAtom, selectedUserAtom } from "../atoms/user";
import { toastAtom } from "../atoms/toast";

//Config
import { nav } from "../config/navigation";

//Get the currently logged in user
export const useGetCurrentCognitoUser = () => {
	const getCurrentCognitoUser = async () => {
		const cognitoUser = await Auth.currentAuthenticatedUser({ bypassCache: true });
		return cognitoUser;
	};

	return getCurrentCognitoUser;
};

//Logout the current user and reset recoil state
export const useLogout = (error) => {
	const { t } = useTranslation();
	const navigate = useNavigate();

	//Recoil Atom States
	const [toast, setToast] = useRecoilState(toastAtom);
	const resetSelectedUser = useResetRecoilState(selectedUserAtom);
	const resetLoginUser = useResetRecoilState(loggedInUserAtom);

	if (error) {
		setToast({
			...toast,
			severity: "error",
			summary: t("login.toast.loginFail.summary"),
			detail: t("login.toast.loginFail.detail", { error: error }),
		});
	}

	const handleLogout = async () => {
		try {
			await Auth.signOut();
			navigate(nav.guest.logout);
			resetSelectedUser();
			resetLoginUser();
		} catch (error) {
			console.error("handleLogout", error);
		}
	};

	return handleLogout;
};

//Is the current session a valid login
export const useIsValidSession = () => {
	const isValidSession = async () => {
		try {
			const currentUser = await Auth.currentAuthenticatedUser();
			if (currentUser.signInUserSession.idToken.payload["cognito:groups"].includes("clinician")) {
				return false;
			} else {
				return true;
			}
		} catch (err) {
			return false;
		}
	};

	return isValidSession;
};
