//Node Modules
import React from "react";
import { useTranslation } from "react-i18next";
import { useForm, Controller } from "react-hook-form";
import { Auth } from "aws-amplify";
import { useNavigate } from "react-router-dom";
import { useRecoilState, useRecoilValue, useResetRecoilState, useSetRecoilState } from "recoil";

//Pages

//BinaryForge Components
import logo from "../../assets/img/steeper-logo-en-GB.svg";

//3rd Party Components
import { Button } from "primereact/button";
import { InputText } from "primereact/inputtext";
import { Password } from "primereact/password";
import { classNames } from "primereact/utils";

//Atoms
import { loggedInUserAtom } from "../../atoms/user";
import { toastAtom } from "../../atoms/toast";

//Helpers
import { nav } from "../../config/navigation";
import { useLogout } from "../../helpers/Auth";
import { passwordValidation } from "../../config/user";

//Other

const CompleteRegistration = () => {
	const { t } = useTranslation();
	const navigate = useNavigate();
	const handleLogout = useLogout();

	const loginUser = useRecoilValue(loggedInUserAtom);
	const [toast, setToast] = useRecoilState(toastAtom);
	const setLoginUser = useSetRecoilState(loggedInUserAtom);
	const resetLoginUser = useResetRecoilState(loggedInUserAtom);

	//React Hook Form
	const defaultValues = {
		username: loginUser ? loginUser.username : "",
		passwordCurrent: loginUser ? loginUser.password : "",
		passwordNew: "",
		passwordConfirm: "",
	};
	const {
		control,
		getValues,
		formState: { errors, isSubmitting },
		handleSubmit,
	} = useForm({
		defaultValues,
		mode: "onTouched",
		reValidateMode: "onChange",
	});

	// Form Error Message
	const getFormErrorMessage = (name) => {
		return errors[name] && <span className="font-error font-small">{errors[name].message}</span>;
	};

	// Login Form Submit
	const onChangePassword = async (data) => {
		try {
			const lazyUsername = data.username.trim(); //Bodge fix because copy and paste username can include whitespace - I don't like this.
			const userWithPendingStatus = await Auth.signIn(lazyUsername, data.passwordCurrent);

			if (userWithPendingStatus.challengeParam) {
				await Auth.completeNewPassword(userWithPendingStatus, data.passwordNew);
				setToast({
					...toast,
					severity: "success",
					summary: t("login.toast.completeRegistration.summary"),
					detail: t("login.toast.completeRegistration.detail"),
				});

				if (userWithPendingStatus.signInUserSession.idToken.payload["cognito:groups"]) {
					const roles = userWithPendingStatus.signInUserSession.idToken.payload["cognito:groups"];
					if (roles.includes("clinician")) {
						const errorMessage = t("login.error.roleNoAccess", { role: t("user.role.clinician") });
						setToast([
							{
								...toast,
								severity: "success",
								summary: t("login.toast.completeRegistration.summary"),
								detail: t("login.toast.completeRegistration.detail"),
							},
							{
								...toast,
								severity: "error",
								summary: t("login.toast.loginFail.summary"),
								detail: t("login.toast.loginFail.detail", { error: errorMessage }),
								life: 6050,
							},
						]);
						navigate(nav.guest.logout);
						resetLoginUser();
					} else {
						navigate(nav.home);
						setLoginUser({ username: lazyUsername, roles: roles });
					}
				} else {
					setToast({
						...toast,
						severity: "error",
						summary: t("login.toast.loginFail.summary"),
						detail: t("login.toast.loginFail.detail", { error: t("login.error.noGroup") }),
					});
					navigate(nav.guest.logout);
					resetLoginUser();
				}
			} else {
				handleLogout(t("login.error.alreadyRegistered", { username: lazyUsername }));
			}
		} catch (err) {
			console.error("onSubmit Error:", err);
			setToast({
				...toast,
				severity: "error",
				summary: t("login.toast.loginFail.summary"),
				detail: t("login.toast.loginFail.detail", { error: err.message }),
			});
		}
	};

	return (
		<main className="appMain">
			<div className="appMain-content">
				<img src={logo} alt="Logo" className="logo marginBottomSmall" />
				<div className="appName marginBottom-xlarge text-center">
					<span className="font-large font-lighter text-uppercase">{t("common.appName")}</span>
				</div>
				<form onSubmit={handleSubmit(onChangePassword)}>
					<div className="formFieldsWrapper">
						<div className="formField">
							<label htmlFor="username">{t("login.username.label")}</label>
							<Controller
								name="username"
								control={control}
								rules={{ required: t("common.form.required") }}
								render={({ field, fieldState }) => (
									<InputText
										id={field.username}
										{...field}
										className={classNames({ "p-error": fieldState.error })}
									/>
								)}
							/>
							{getFormErrorMessage("username")}
						</div>
						<div className="formField">
							<label htmlFor="passwordCurrent">{t("login.passwordCurrent.label")}</label>
							<Controller
								name="passwordCurrent"
								control={control}
								rules={{ required: t("common.form.required") }}
								render={({ field: { ref, ...newField }, fieldState }) => (
									<Password
										{...newField}
										inputRef={ref}
										feedback={false}
										toggleMask={true}
										className={classNames({ "p-error": fieldState.error })}
									/>
								)}
							/>
							{getFormErrorMessage("passwordCurrent")}
						</div>
						<div className="formField">
							<label htmlFor="passwordNew">{t("login.passwordNew.label")}</label>
							<Controller
								name="passwordNew"
								control={control}
								rules={{
									required: t("common.form.required"),
									pattern: {
										value: passwordValidation,
										message: t("common.form.password"),
									},
									validate: {
										match: (value) =>
											getValues("passwordCurrent") !== value || t("login.passwordNew.match"),
									},
								}}
								render={({ field: { ref, ...newField }, fieldState }) => (
									<Password
										{...newField}
										inputRef={ref}
										feedback={false}
										toggleMask={true}
										className={classNames({ "p-error": fieldState.error })}
									/>
								)}
							/>
							{getFormErrorMessage("passwordNew")}
						</div>
						<div className="formField">
							<label htmlFor="passwordConfirm">{t("login.passwordConfirm.label")}</label>
							<Controller
								name="passwordConfirm"
								control={control}
								rules={{
									required: t("common.form.required"),
									validate: {
										match: (value) =>
											getValues("passwordNew") === value || t("login.passwordConfirm.match"),
									},
								}}
								render={({ field: { ref, ...newField }, fieldState }) => (
									<Password
										{...newField}
										inputRef={ref}
										feedback={false}
										toggleMask={true}
										className={classNames({ "p-error": fieldState.error })}
									/>
								)}
							/>
							{getFormErrorMessage("passwordConfirm")}
						</div>
					</div>

					<Button
						label={isSubmitting ? t("login.completingRegistration") : t("login.completeRegistration")}
						type="submit"
						className="feature"
						disabled={isSubmitting}
					/>
				</form>
			</div>
		</main>
	);
};

export default CompleteRegistration;
