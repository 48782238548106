import { useTranslation } from "react-i18next";
import { Skeleton } from "primereact/skeleton";

const UserDetailsSkeleton = () => {
	const { t } = useTranslation();

	return (
		<div className="card">
			<div className="flex aItems-center jContent-spaceBetween marginBottom-large">
				<div className="font-large flex">
					<span className="font-bold">{t("firmware.table.name")}: </span>
					<span>
						<Skeleton width="35%" />
					</span>
				</div>
			</div>
			<div className="grid columns-3 gapCol-large">
				<div className="grid columns-label gapCol-medium gapRow-small aItems-center aContent-start">
					<span>{t("firmware.table.version")}:</span>
					<span>
						<Skeleton width="75%" />
					</span>
					<span>{t("firmware.table.status")}:</span>
					<span>
						<Skeleton width="85%" />
					</span>
					<span>{t("firmware.table.product")}:</span>
					<span>
						<Skeleton width="60%" />
					</span>
				</div>
				<div className="grid columns-label gapCol-medium gapRow-small aItems-center aContent-start">
					<span>{t("firmware.table.filename")}:</span>
					<span>
						<Skeleton width="65%" />
					</span>
					<span>{t("firmware.table.filesize")}:</span>
					<span>
						<Skeleton width="45%" />
					</span>
					<span>{t("firmware.table.filedate")}:</span>
					<span>
						<Skeleton width="85%" />
					</span>
				</div>
				<div className="grid columns-label gapCol-medium gapRow-small aItems-center aContent-start">
					<span>{t("firmware.table.downloads")}:</span>
					<span>
						<Skeleton width="10%" />
					</span>
					<span>{t("firmware.table.uploadedAt")}:</span>
					<span>
						<Skeleton width="75%" />
					</span>
					<span>{t("firmware.table.uploadedBy")}:</span>
					<span>
						<Skeleton width="85%" />
					</span>
				</div>
			</div>
		</div>
	);
};

export default UserDetailsSkeleton;
