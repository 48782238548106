import i18n from "../i18n";

export const usernameValidation = /^[\w@._-]+$/;
export const emailValidation =
	/^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
export const passwordValidation =
	/^(?=^.{12,}$)(?=.*\d)(?=.*[A-Z])(?=.*[a-z])(?=.*[@$!%*?&():;#"^_{}[\]'~|<>,./=`\\+-]+).*$/;

export const roleOptions = [
	{ value: "clinician", label: i18n.t("user.role.clinician") },
	{ value: "support", label: i18n.t("user.role.support") },
	{ value: "firmware", label: i18n.t("user.role.firmware") },
	{ value: "technician", label: i18n.t("user.role.technician") },
];

export const roleOptionsFilter = [
	{ value: "clinician", label: i18n.t("user.role.clinician") },
	{ value: "support", label: i18n.t("user.role.support") },
	{ value: "firmware", label: i18n.t("user.role.firmware") },
	{ value: "technician", label: i18n.t("user.role.technician") },
	{ value: "none", label: i18n.t("user.role.none") },
];
