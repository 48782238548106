// import { API } from "aws-amplify";
import { API } from "aws-amplify";
import { atom, selector, selectorFamily } from "recoil";
import { recoilPersist } from "recoil-persist";
import i18n from "../i18n";

const { persistAtom } = recoilPersist();

const apiName = "steeperApi";

export const loggedInUserAtom = atom({
	key: "loggedInUserAtom",
	default: { roles: [] },
	effects_UNSTABLE: [persistAtom],
});

export const usersAtom = atom({
	key: "usersAtom",
	default: null,
});

export const usersSelector = selector({
	key: "usersSelector",
	get: async () => {
		const users = await API.get(apiName, "/user");
		return users;
	},
	set: ({ set }, newValue) => {
		set(usersAtom, newValue);
	},
});

export const clinicianSelector = selector({
	key: "clinicianSelector",
	get: ({ get }) => {
		let clinicians = null;
		const allUsers = get(usersAtom);
		if (allUsers) clinicians = allUsers.filter((u) => u.roles.includes("clinician"));
		return clinicians;
	},
});

export const selectedUserAtom = atom({
	key: "selectedUserAtom",
	default: null,
});

export const selectedUserSelector = selector({
	key: "selectedUserSelector",
	get: async ({ get }) => {
		let user = null;
		const selectedUser = get(selectedUserAtom);
		if (selectedUser) {
			user = await API.get(apiName, `/user/${selectedUser.username}`);
		}
		return user;
	},
});

export const usernameSelectorFamily = selectorFamily({
	key: "usernameSelectorFamily",
	get: (sub) => async () => {
		const users = await API.get(apiName, `/user/byAttribute?name=sub&type=%3D&value=${sub}`);
		return users.length ? users[0] : { fullname: `${i18n.t("common.deletedUser")}: ${sub.slice(-4)}` };
	},
});
