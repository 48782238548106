//Node Modules
import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useSetRecoilState, useRecoilValue, useResetRecoilState, useRecoilRefresher_UNSTABLE } from "recoil";

//Pages

//BinaryForge Components
import { PageHeader } from "../../../components/layout";
import { FirmwareDetails, FirmwareList, FirmwareUploadDialog } from "../../../components/firmware";

//3rd Party Components
import { HiOutlineChip } from "react-icons/hi";
import { Button } from "primereact/button";

//Atoms
import { dialogAtomFamily } from "../../../atoms/dialog";
import { selectedFirmwareAtom, firmwareListSelector } from "../../../atoms/firmware";

//Helpers

//Other

const Firmware = () => {
	//Hooks
	const { t } = useTranslation();

	//Recoil
	const setShowFirmwareUpload = useSetRecoilState(dialogAtomFamily("firmwareUploadDialog"));
	const selectedFirmware = useRecoilValue(selectedFirmwareAtom);
	const refreshFirmwareCache = useRecoilRefresher_UNSTABLE(firmwareListSelector);
	const resetSelectedFirmware = useResetRecoilState(selectedFirmwareAtom);

	useEffect(() => {
		refreshFirmwareCache();

		return () => {
			resetSelectedFirmware();
		};
	}, []);

	const Actions = () => {
		return (
			<>
				<Button
					onClick={() => setShowFirmwareUpload(true)}
					className="feature"
					label={t("firmware.actions.upload")}
				/>
			</>
		);
	};

	return (
		<>
			<PageHeader
				title={t("firmware.pageTitle")}
				subtitle={t("firmware.pageSubtitle")}
				icon={<HiOutlineChip />}
				actions={<Actions />}
			/>
			<main className="appMain">
				<div className="appMain-content">
					<section>
						<FirmwareList />
					</section>
					{selectedFirmware && (
						<section>
							<FirmwareDetails />
						</section>
					)}
				</div>
			</main>

			<FirmwareUploadDialog />
		</>
	);
};

export default Firmware;
