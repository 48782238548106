//Node Modules
import { useRecoilValueLoadable, useSetRecoilState } from "recoil";
import { useTranslation } from "react-i18next";
import _ from "lodash";

//Pages

//BinaryForge Components
import { DatatableItem, Username } from "../helpers";
import { FirmwareDetailsSkeleton } from "../helpers/skeleton";
import { FirmwareUser, FirmwareStatusDialog, FirmwareMd5Dialog } from "./";

//3rd Party Components
import { Button } from "primereact/button";
import { Timeline } from "primereact/timeline";

//Atoms
import { selectedFirmwareSelector } from "../../atoms/firmware";
import { dialogAtomFamily } from "../../atoms/dialog";

//Helpers
import { getUiDateTime } from "../../helpers/DateUtils";
import { auditMarker } from "../../config/firmware";

//Other
import { firmwareStatusOptions } from "../../config/firmware";

const FirmwareDetails = () => {
	//Hooks
	const { t } = useTranslation();

	//Recoil
	const { state: selectedFirmwareState, contents: selectedFirmwareData } =
		useRecoilValueLoadable(selectedFirmwareSelector);
	const setShowMd5Dialog = useSetRecoilState(dialogAtomFamily("firmwareMd5Dialog"));
	const setShowFirmwareStatusDialog = useSetRecoilState(dialogAtomFamily("firmwareStatusDialog"));

	const auditTimelineMarker = (item) => {
		const markerIcon = auditMarker[item.action];
		return (
			<span className="customTimelineMarker">
				<i className={`pi ${markerIcon}`}></i>
			</span>
		);
	};

	const auditTimelineEvent = (item) => {
		return (
			<div className="flexVert font-light font-small">
				<span>{getUiDateTime(item.createdAt)}</span>
				<span>
					<Username sub={item.createdBy} />
				</span>
			</div>
		);
	};

	const auditTimelineContent = (item) => {
		return (
			<div className="flexVert">
				<span className="font-bold">{t(`firmware.audit.action.${item.action}`)}</span>
				{item.comment && (
					<div className="flex aItems-center gap-xsmall">
						<i className="pi pi-comment font-light" />
						<span>{item.comment}</span>
					</div>
				)}
			</div>
		);
	};

	switch (selectedFirmwareState) {
		case "hasError":
			//TODO: Show a proper error component here
			return <div>{selectedFirmwareData.message ? selectedFirmwareData.message : selectedFirmwareData}</div>;
		case "loading":
			return <FirmwareDetailsSkeleton />;
		case "hasValue":
			return (
				<>
					<div className="card">
						<div className="flex aItems-center jContent-spaceBetween marginBottom-large">
							<div className="font-large">
								<span className="font-bold">{t("firmware.table.name")}: </span>
								<span>{selectedFirmwareData.name}</span>
							</div>
							<div className="flex gap-small jContent-end">
								<Button
									onClick={() => setShowFirmwareStatusDialog(true)}
									label={t("firmware.actions.status")}
									className="feature"
									disabled={selectedFirmwareData.status === 4}
								/>
							</div>
						</div>
						<div className="grid columns-3 gapCol-large">
							<div className="grid columns-label gapCol-medium gapRow-xsmall aItems-center aContent-start">
								<span>{t("firmware.table.version")}:</span>
								<span>{selectedFirmwareData.version}</span>
								<span>{t("firmware.table.status")}:</span>
								<span>
									<DatatableItem
										type="text"
										value={t(`firmware.status.${selectedFirmwareData.status}`)}
										wrapperStyle={firmwareStatusOptions[selectedFirmwareData.status].colour}
									/>
								</span>
								<span>{t("firmware.table.product")}:</span>
								<span>{selectedFirmwareData.product.name}</span>
							</div>
							<div className="grid columns-label gapCol-medium gapRow-xsmall aItems-center aContent-start">
								<span>{t("firmware.table.filename")}:</span>
								<span>{selectedFirmwareData.filename}</span>
								<span>{t("firmware.table.filesize")}:</span>
								<span>
									{selectedFirmwareData.fileSize / 1000} <span className="font-lighter">kb</span>
								</span>
								<span>{t("firmware.table.filedate")}:</span>
								<span>{getUiDateTime(selectedFirmwareData.fileModified)}</span>
								{selectedFirmwareData.md5 && (
									<>
										<span>{t("firmware.table.md5")}:</span>
										<span onClick={() => setShowMd5Dialog(true)} className="link">
											{selectedFirmwareData.md5.slice(0, 12)}...
										</span>
									</>
								)}
							</div>
							<div className="grid columns-label gapCol-medium gapRow-xsmall aItems-center aContent-start">
								<span>{t("firmware.table.downloads")}</span>
								<span>{selectedFirmwareData.downloads}</span>
								<span>{t("firmware.table.uploadedAt")}:</span>
								<span>{getUiDateTime(selectedFirmwareData.createdAt)}</span>
								<span>{t("firmware.table.uploadedBy")}:</span>
								<span>
									<FirmwareUser />
								</span>
							</div>
						</div>
						<div className="marginTop-large">
							<header>
								<h3>{t("firmware.audit.title")}</h3>
							</header>
							<Timeline
								value={selectedFirmwareData.firmwareAudit}
								marker={auditTimelineMarker}
								content={auditTimelineContent}
								opposite={auditTimelineEvent}
							/>
						</div>
					</div>
					<FirmwareStatusDialog selectedFirmware={selectedFirmwareData} />
					<FirmwareMd5Dialog md5Hash={selectedFirmwareData.md5} />
				</>
			);
		default:
			return null;
	}
};

export default FirmwareDetails;
