//Node Modules
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useForm, Controller } from "react-hook-form";
import { Auth } from "aws-amplify";
import { useRecoilState, useRecoilValue, useSetRecoilState } from "recoil";

//Pages

//BinaryForge Components

//3rd Party Components
import { Button } from "primereact/button";
import { InputText } from "primereact/inputtext";
import { classNames } from "primereact/utils";

//Atoms
import { toastAtom } from "../../atoms/toast";
import { Note } from "../helpers";
import { usersAtom } from "../../atoms/user";
import { dialogAtomFamily } from "../../atoms/dialog";

//Helpers
import { useApiRequest } from "../../helpers/Api";

//Other

const UserProfileAttributes = ({ currentUser }) => {
	const { t } = useTranslation();
	const apiRequest = useApiRequest();
	const { attributes } = currentUser;
	const roles = currentUser.signInUserSession.idToken.payload["cognito:groups"];

	//Recoil State
	const setLoading = useSetRecoilState(dialogAtomFamily("loadingDialog"));
	const [toast, setToast] = useRecoilState(toastAtom);
	const users = useRecoilValue(usersAtom);

	//Local State
	const [emailWarn, setEmailWarn] = useState(false);
	const [emailCode, setEmailCode] = useState("");

	useEffect(() => {
		if (!attributes.email_verified) {
			setEmailWarn(true);
		}
	}, [attributes]);

	//React Hook Form
	const defaultValues = {
		fullname: attributes.name,
		email: attributes.email,
	};

	const {
		control,
		setValue,
		formState: { errors, isSubmitting },
		handleSubmit,
	} = useForm({
		defaultValues,
		mode: "onTouched",
		reValidateMode: "onChange",
	});

	// Form Error Message
	const getFormErrorMessage = (name) => {
		return errors[name] && <span className="font-error font-small">{errors[name].message}</span>;
	};

	// Update Attributes
	const handleUpdateAttributes = async (data) => {
		try {
			setLoading({ visible: true, message: t("profile.attributes.loading") });
			await Auth.updateUserAttributes(currentUser, { name: data.fullname });

			if (data.email !== attributes.email) {
				await validateUniqueEmail(data.email);
				setEmailWarn(true);
				await Auth.updateUserAttributes(currentUser, { email: data.email });
				setToast({
					...toast,
					severity: "info",
					summary: t("profile.attributes.toast.successSummaryEmail"),
					detail: t("profile.attributes.toast.successDetailEmail"),
				});
			} else {
				setToast({
					...toast,
					severity: "success",
					summary: t("profile.attributes.toast.successSummary"),
					detail: t("profile.attributes.toast.successDetail"),
				});
			}
		} catch (err) {
			console.error("Update User Attr Error ::", err);
			setToast({
				...toast,
				severity: "error",
				summary: t("profile.attributes.toast.errorSummary"),
				detail: t("profile.attributes.toast.errorDetail", { error: err.message }),
			});
		} finally {
			setLoading({ visible: false });
		}
	};

	const validateUniqueEmail = async (newEmail) => {
		let existingUsers = users;
		if (!users) existingUsers = await apiRequest("get", "/user", null, null);
		const existingEmails = existingUsers.map((u) => u.email);
		if (existingEmails.includes(newEmail))
			throw Error(t("user.userError.emailExists", { user: { email: newEmail } }));
	};

	const handleVerifyEmail = async () => {
		try {
			await Auth.verifyCurrentUserAttributeSubmit("email", emailCode);
			await Auth.currentAuthenticatedUser({ bypassCache: true });

			const session = await Auth.currentSession();
			setEmailWarn(false);
			setValue("email", session.idToken.payload.email);

			setToast({
				...toast,
				severity: "success",
				summary: t("profile.email.toast.successSummary"),
				detail: t("profile.email.toast.successDetail"),
			});
		} catch (err) {
			setToast({
				...toast,
				severity: "error",
				summary: t("profile.email.toast.errorSummary"),
				detail: t("profile.email.toast.errorDetail", { error: err.message }),
			});
		}
	};

	return (
		<section>
			<div className="card">
				<header>
					<h3>{t("profile.attributes.title")}</h3>
				</header>

				<div className="flex gap-medium marginBottom-small">
					<span className="label">{t("user.userForm.role.label")}:</span>
					<span>{roles.map((role) => t(`user.role.${role}`)).join(", ")}</span>
				</div>

				<form onSubmit={handleSubmit(handleUpdateAttributes)}>
					<div className="formFieldsWrapper grid columns-2 gap-large">
						<div className="formField">
							<label htmlFor="fullname">{t("user.userForm.fullname.label")}</label>
							<Controller
								name="fullname"
								control={control}
								rules={{
									required: t("common.form.required"),
									pattern: { value: /^[A-Za-z\- ]+$/i, message: t("user.userForm.fullname.pattern") },
								}}
								render={({ field, fieldState }) => (
									<InputText
										id={field.fullname}
										{...field}
										className={classNames({ "p-error": fieldState.error })}
									/>
								)}
							/>
							{getFormErrorMessage("fullname")}
						</div>

						<div>
							<div className="formField">
								<label htmlFor="email">{t("user.userForm.email.label")}</label>
								<Controller
									name="email"
									control={control}
									rules={{
										required: t("common.form.required"),
										pattern: {
											value: /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
											message: t("user.userForm.email.pattern"),
										},
									}}
									render={({ field, fieldState }) => (
										<InputText
											id={field.email}
											{...field}
											className={classNames({ "p-error": fieldState.error })}
											readOnly={emailWarn}
										/>
									)}
								/>
								{getFormErrorMessage("email")}
							</div>

							{emailWarn && (
								<div className="flexVert gap-small marginTop-small">
									<div className="flex gap-medium">
										<input
											type="text"
											value={emailCode}
											placeholder={t("profile.email.title")}
											onChange={(e) => setEmailCode(e.target.value)}
										/>
										<button type="button" onClick={handleVerifyEmail}>
											{t("profile.email.submit")}
										</button>
									</div>
									<Note
										messageKey={t("profile.email.verifyWarning")}
										messageStyle="font-small"
										icon="pi-exclamation-triangle font-warn"
									/>
									<Note
										messageKey={t("profile.email.verifyInfo")}
										messageStyle="font-small"
										icon="pi-info-circle font-info"
									/>
								</div>
							)}
						</div>
					</div>
					<Button
						type="submit"
						label={t("profile.attributes.submit")}
						className="feature aSelf-start"
						disabled={isSubmitting}
					/>
				</form>
			</div>
		</section>
	);
};

export default UserProfileAttributes;
