//Node Modules
import { useRecoilValueLoadable } from "recoil";

//Pages

//BinaryForge Components
import { UserListTable } from "./";
import { Note } from "../helpers";

//3rd Party Components

//Atoms
import { usersSelector } from "../../atoms/user";
import { DatatableSkeleton } from "../helpers/skeleton";

//Helpers

//Other

const UserList = () => {
	//Recoil
	const { state, contents } = useRecoilValueLoadable(usersSelector);

	switch (state) {
		case "hasError":
			//TODO: Show a proper error component here
			return (
				<Note
					messageKey={`Error: ${contents.message ? contents.message : contents}`}
					icon="pi-exclamation-circle font-error"
				/>
			);
		case "loading":
			return <DatatableSkeleton columns={["username", "fullname", "email", "roles", "test access", "active"]} />;
		case "hasValue":
			return <UserListTable userData={contents} />;
		default:
			return null;
	}
};

export default UserList;
