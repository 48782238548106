//Node Modules
import React from "react";
import { useSetRecoilState } from "recoil";
import { useTranslation } from "react-i18next";

//BinaryForge Components
import { BfDialog } from "../helpers";
import { ClinicForm } from "./";

//3rd Party Components
import { Button } from "primereact/button";

//Atoms
import { dialogAtomFamily } from "../../atoms/dialog";
import { triggerSubmitAtom } from "../../atoms/form";

const ClinicEditDialog = () => {
	// Hooks
	const { t } = useTranslation();

	// Recoil
	const setShow = useSetRecoilState(dialogAtomFamily("clinicEditDialog"));
	const setTriggerSubmit = useSetRecoilState(triggerSubmitAtom);

	// Footer
	const footer = (
		<>
			<Button label={t("common.button.cancel")} onClick={() => setShow(false)} />
			<Button
				label={t("common.button.save")}
				icon="pi pi-save"
				className="feature"
				// disabled={!isValid}
				onClick={() => setTriggerSubmit(true)}
			/>
		</>
	);

	return (
		<BfDialog id="clinicEditDialog" header={t("clinic.dialog.edit.title")} footer={footer}>
			<ClinicForm type="clinicEdit" />
		</BfDialog>
	);
};

export default ClinicEditDialog;
