//Node Modules
import { Routes, Route } from "react-router-dom";
import { useRecoilValue } from "recoil";

//Pages
import { CompleteRegistration, Login, ForgotPassword, MfaChallenge, MobileApp } from "../../pages/guest";
import { Firmware, Configuration } from "../../pages/auth/firmware";
import { User, Profile, Clinic } from "../../pages/auth/user";
import { VerifyAttribute } from "../../pages/combo";
import Break from "../../pages/auth/Break";

//Atoms
import { loggedInUserAtom } from "../../atoms/user";

//Navigation
import { nav } from "../../config/navigation";
import GuestRoute from "./GuestRoute";
import AuthRoute from "./AuthRoute";

const NavRoutes = () => {
	const { roles } = useRecoilValue(loggedInUserAtom);

	const getHomePage = () => {
		if (!roles) return <Login />;
		if (roles.includes("support")) return <User />;
		else if (roles.includes("firmware")) return <Firmware />;
		else if (roles.includes("technician")) return <Configuration />;
		else return <Profile />;
	};

	return (
		<Routes>
			<Route
				exact
				path={nav.guest.login}
				element={
					<GuestRoute>
						<Login />
					</GuestRoute>
				}
			/>
			<Route
				exact
				path={nav.guest.logout}
				element={
					<GuestRoute>
						<Login />
					</GuestRoute>
				}
			/>
			<Route
				exact
				path={nav.guest.completeReg}
				element={
					<GuestRoute>
						<CompleteRegistration />
					</GuestRoute>
				}
			/>
			<Route
				exact
				path={nav.guest.forgotPassword}
				element={
					<GuestRoute>
						<ForgotPassword />
					</GuestRoute>
				}
			/>
			<Route
				exact
				path={nav.guest.mfa}
				element={
					<GuestRoute>
						<MfaChallenge />
					</GuestRoute>
				}
			/>
			<Route
				exact
				path={nav.guest.mobileApp}
				element={
					<GuestRoute>
						<MobileApp />
					</GuestRoute>
				}
			/>
			<Route
				exact
				path="/"
				element={
					<AuthRoute>
						{/* <Home /> */}
						{getHomePage()}
					</AuthRoute>
				}
			/>
			<Route
				exact
				path={nav.home}
				element={
					<AuthRoute>
						{/* <Home /> */}
						{getHomePage()}
					</AuthRoute>
				}
			/>
			<Route
				exact
				path={nav.firmware.base}
				element={
					<AuthRoute>
						<Firmware />
					</AuthRoute>
				}
			/>
			<Route
				exact
				path={nav.firmware.configuration}
				element={
					<AuthRoute>
						<Configuration />
					</AuthRoute>
				}
			/>
			<Route
				path={nav.user.base}
				element={
					<AuthRoute>
						<User />
					</AuthRoute>
				}
			/>
			<Route
				path={nav.clinic.base}
				element={
					<AuthRoute>
						<Clinic />
					</AuthRoute>
				}
			/>
			<Route
				path={nav.user.base + nav.user.profile}
				element={
					<AuthRoute>
						<Profile />
					</AuthRoute>
				}
			/>
			<Route path={nav.combo.verifyAttribute} element={<VerifyAttribute />} />
			{process.env.REACT_APP_BREAK === "true" && (
				<Route
					path={nav.break}
					element={
						<AuthRoute>
							<Break />
						</AuthRoute>
					}
				/>
			)}
		</Routes>
	);
};

export default NavRoutes;
