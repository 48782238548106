//Node Modules
import { useRecoilValueLoadable, useRecoilValue } from "recoil";
import { useTranslation } from "react-i18next";

//Pages

//BinaryForge Components
import { DatatableItem, HighlightItem } from "../helpers";
import { UserDetailsSkeleton } from "../helpers/skeleton";

//3rd Party Components

//Atoms
import { selectedUserSelector } from "../../atoms/user";

//Helpers
import { getUiDateTime } from "../../helpers/DateUtils";
import { clinicOptionsAtom } from "../../atoms/clinic";

//Other

const UserDetails = () => {
	//Hooks
	const { t } = useTranslation();

	//Recoil
	const { state: selectedUserState, contents: selectedUserData } = useRecoilValueLoadable(selectedUserSelector);
	const clinicOptions = useRecoilValue(clinicOptionsAtom);

	const getClinicName = (userClinic) => {
		if (userClinic) {
			const clinic = clinicOptions.find((c) => c.id === userClinic);
			return clinic.name;
		} else {
			return t("common.notset");
		}
	};

	switch (selectedUserState) {
		case "hasError":
			//TODO: Show a proper error component here
			return <div>{selectedUserData.message ? selectedUserData.message : selectedUserData}</div>;
		case "loading":
			return <UserDetailsSkeleton />;
		case "hasValue":
			return (
				<>
					<div className="grid columns-3 gapCol-medium">
						<div className="grid columns-label gapCol-medium gapRow-small aItems-center aContent-start">
							<span>{t("user.table.fullname")}:</span>
							<span>{selectedUserData.fullname}</span>
							<span>{t("user.table.email")}:</span>
							<span>{selectedUserData.email}</span>
							<span>{t("user.table.role")}:</span>
							<span>{selectedUserData.roles.map((role) => t(`user.role.${role}`)).join(", ")}</span>
							{selectedUserData.roles.includes("clinician") && (
								<>
									<span>{t("user.table.clinic")}:</span>
									<span>{getClinicName(selectedUserData.clinic)}</span>
								</>
							)}
							<span>{t("user.table.test")}:</span>
							<span>
								<DatatableItem
									type="full"
									value={
										selectedUserData.testFirmwareAccess
											? t("common.boolean.yes")
											: t("common.boolean.no")
									}
									icon={selectedUserData.testFirmwareAccess ? "pi-check-circle" : "pi-times-circle"}
									wrapperStyle={selectedUserData.testFirmwareAccess ? "success" : "error"}
								/>
							</span>
						</div>
						<div className="grid columns-label gapCol-medium gapRow-xsmall aItems-center aContent-start">
							<span>{t("user.table.active")}:</span>
							<span>
								<DatatableItem
									type="full"
									value={
										selectedUserData.active
											? t("common.boolean.active")
											: t("common.boolean.inactive")
									}
									icon={selectedUserData.active ? "pi-check-circle" : "pi-times-circle"}
									wrapperStyle={selectedUserData.active ? "success" : "error"}
								/>
							</span>
							<span>{t("user.table.status")}:</span>
							<span>
								<DatatableItem
									type="text"
									value={t(`user.status.${selectedUserData.status}`)}
									// icon={selectedUserData.status ? "pi-lock" : "pi-lock-open"}
									wrapperStyle={selectedUserData.status === "CONFIRMED" ? "success" : "error"}
								/>
							</span>
							<span>{t("user.table.mfa")}:</span>
							<span>
								<DatatableItem
									type="full"
									value={
										selectedUserData.mfa
											? t(`profile.mfa.type.${selectedUserData.mfa}`)
											: t(`profile.mfa.type.NONE`)
									}
									icon={selectedUserData.mfa ? "pi-lock" : "pi-lock-open"}
									wrapperStyle={selectedUserData.mfa ? "success" : "error"}
								/>
							</span>
						</div>
						<div className="grid columns-label gapCol-medium gapRow-small aItems-center aContent-start">
							<span>{t("common.table.createdAt")}:</span>
							<span>{getUiDateTime(selectedUserData.created)}</span>
							<span>{t("common.table.updatedAt")}:</span>
							<span>{getUiDateTime(selectedUserData.updated)}</span>
						</div>
					</div>
					{selectedUserData.authEvents && (
						<div className="grid columnsAuto-2 jContent-start gap-large marginTop-large">
							<div className="grid columns-label gapCol-medium gapRow-small aItems-center aContent-start">
								<span>{t("user.login.last")}:</span>
								<span>
									<HighlightItem
										type="full"
										value={getUiDateTime(selectedUserData.authEvents.lastLogin.date)}
										icon={
											selectedUserData.authEvents.lastLogin.response === "Pass"
												? "pi-check-circle"
												: "pi-times-circle"
										}
										wrapperStyle={
											selectedUserData.authEvents.lastLogin.response === "Pass"
												? "font-success"
												: "font-error"
										}
									/>
								</span>
							</div>
							<div className="grid columns-label gapCol-medium gapRow-small aItems-center aContent-start">
								<span>{t("user.login.lastSuccess")}:</span>
								<span>
									{selectedUserData.authEvents.lastSuccess ? (
										<HighlightItem
											type="full"
											//TODO: handle null value
											value={getUiDateTime(selectedUserData.authEvents.lastSuccess.date)}
											icon={
												selectedUserData.authEvents.lastSuccess.response === "Pass"
													? "pi-check-circle"
													: "pi-times-circle"
											}
											wrapperStyle={
												selectedUserData.authEvents.lastSuccess.response === "Pass"
													? "font-success"
													: "font-error"
											}
										/>
									) : (
										t("user.login.noLastSuccess")
									)}
								</span>
							</div>
						</div>
					)}
				</>
			);
		default:
			return null;
	}
};

export default UserDetails;
