import i18n from "../i18n";

export const productRangeOptions = [{ id: 1, name: i18n.t("firmware.product.1") }];

export const auditMarker = {
	"create.true": "pi-cloud-upload",
	"create.false": "pi-cloud-upload",
	active: "pi-check",
	inactive: "pi-times",
	status0: "pi-cloud-upload",
	status1: "pi-check-square",
	status2: "pi-users",
	status3: "pi-fast-forward",
	status4: "pi-times",
};

export const firmwareStatusOptions = [
	{ value: 0, label: i18n.t("firmware.status.0"), colour: "info" },
	{ value: 1, label: i18n.t("firmware.status.1"), colour: "warn" },
	{ value: 2, label: i18n.t("firmware.status.2"), colour: "success" },
	{ value: 3, label: i18n.t("firmware.status.3"), colour: "error" },
	{ value: 4, label: i18n.t("firmware.status.4"), colour: "error" },
];

export const getAvailableFirmwareStatusOptions = (currentStatus) => {
	let options = [];

	switch (currentStatus) {
		case 0:
			options = [
				firmwareStatusOptions[0],
				firmwareStatusOptions[1],
				firmwareStatusOptions[2],
				firmwareStatusOptions[3],
				firmwareStatusOptions[4],
			];
			break;
		case 1:
			options = [
				firmwareStatusOptions[1],
				firmwareStatusOptions[2],
				firmwareStatusOptions[3],
				firmwareStatusOptions[4],
			];
			break;
		case 2:
			options = [firmwareStatusOptions[2], firmwareStatusOptions[3], firmwareStatusOptions[4]];
			break;
		case 3:
			options = [firmwareStatusOptions[3], firmwareStatusOptions[1], firmwareStatusOptions[2]];
			break;
		case 4:
			options = [firmwareStatusOptions[4]];
			break;
		case 5:
			options = [firmwareStatusOptions[3], firmwareStatusOptions[4]];
			break;
		default:
			options = firmwareStatusOptions;
			break;
	}

	return options;
};

export const acceptedFileTypes = ["application/zip", "application/x-zip-compressed"];
