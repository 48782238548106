import { BlobReader, ZipReader } from "@zip.js/zip.js";
import { MD5 } from "crypto-js";
import { acceptedFileTypes } from "../config/firmware";

export const validateFirmwareFile = async (file) => {
	//Check the filesize > 0
	if (file.size <= 0) throw Error("firmware.validation.filesize");

	//Check the filetype is .zip
	if (!acceptedFileTypes.includes(file.type)) throw Error("firmware.validation.type");

	//Expand the file and check it contains 1 or more files
	const zipFileReader = new BlobReader(file);
	const zipReader = new ZipReader(zipFileReader);
	const zipFiles = await zipReader.getEntries();

	for (const zipFile of zipFiles) {
		//Check the expanded files have size > 0
		if (zipFile.compressedSize <= 0) throw Error("firmware.validation.entrySize");

		//Check the files have the format [0-9]*-[0-9]*-[a-zA-Z0-9]{8}.comp
		const regExFormat = new RegExp("^([0-9]*-[0-9]*-[a-zA-Z0-9]{8}.comp)$");
		if (!regExFormat.test(zipFile.filename)) throw Error("firmware.validation.entryName");
	}
};

export const compareFirmwareVersions = async (current, comparison) => {
	const currentArray = current ? current.split(".") : ["0", "0"];
	const comparisonArray = comparison.split(".");

	const currentNumber = Number(currentArray[0]) * 100 + Number(currentArray[1]);
	const comparisonNumber = Number(comparisonArray[0]) * 100 + Number(comparisonArray[1]);

	const valid = currentNumber >= comparisonNumber ? false : true;

	return valid;
};

export const getMD5 = (file) => {
	const hash = MD5(file).toString();
	return hash;
};
