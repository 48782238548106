//Node Modules
import React from "react";
import { useTranslation } from "react-i18next";
import { useRecoilState, useRecoilValue } from "recoil";

//Pages

//BinaryForge Components

//3rd Party Components
import { HiOutlineChip } from "react-icons/hi";
import { TbBuildingHospital } from "react-icons/tb";

//Atoms
import { themeAtom } from "../../atoms/theme";
import { Link } from "react-router-dom";

//Helpers
import { useLogout } from "../../helpers/Auth";

//Other
import { nav } from "../../config/navigation";
import { loggedInUserAtom } from "../../atoms/user";

const AppNav = () => {
	const { t } = useTranslation();
	const handleLogout = useLogout();

	// Recoil State
	const [theme, setTheme] = useRecoilState(themeAtom);
	const { roles } = useRecoilValue(loggedInUserAtom);

	const handleToggleTheme = () => {
		document.body.setAttribute("data-theme", theme === "light" ? "dark" : "light");
		setTheme((prevState) => (prevState === "light" ? "dark" : "light"));
	};

	return (
		<nav className="appNav flex gap-medium">
			{process.env.REACT_APP_BREAK === "true" && (
				<Link to={nav.break} className="navItem">
					<i className="pi pi-exclamation-triangle" />
					<span>Break Me</span>
				</Link>
			)}
			{roles.includes("firmware") && (
				<Link to={nav.firmware.base} className="navItem">
					<HiOutlineChip />
					<span>{t("nav.firmware")}</span>
				</Link>
			)}
			{roles.includes("technician") && (
				<Link to={nav.firmware.configuration} className="navItem">
					<i className="pi pi-cloud-upload" />
					<span>{t("nav.configuration")}</span>
				</Link>
			)}
			{roles.includes("support") && (
				<Link to={nav.user.base} className="navItem">
					<i className="pi pi-users" />
					<span>{t("nav.users")}</span>
				</Link>
			)}
			{roles.includes("support") && (
				<Link to={nav.clinic.base} className="navItem">
					<TbBuildingHospital />
					<span>{t("nav.clinics")}</span>
				</Link>
			)}
			<div className="font-lighter">::</div>
			<Link to={nav.user.base + nav.user.profile} className="navItem">
				<i className="pi pi-user" />
			</Link>
			<div
				onClick={() => {
					handleLogout();
				}}
				className="navItem link">
				<i className="pi pi-sign-out" />
			</div>
			<div className="font-lighter">::</div>
			<div className="navItem link" onClick={() => handleToggleTheme()}>
				<i className={`pi ${theme === "light" ? "pi-sun" : "pi-moon"}`} />
			</div>
		</nav>
	);
};

export default AppNav;
