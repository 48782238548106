import { useTranslation } from "react-i18next";
import { Skeleton } from "primereact/skeleton";

const UserDetailsSkeleton = () => {
	const { t } = useTranslation();

	return (
		//TODO: Lables can be added and don't need to be a skeleton.
		<div className="grid columns-3 gapCol-large">
			<div className="grid columns-label gapCol-medium gapRow-small aItems-center aContent-start">
				<span>{t("user.table.fullname")}:</span>
				<span>
					<Skeleton width="75%" />
				</span>
				<span>{t("user.table.email")}:</span>
				<span>
					<Skeleton width="85%" />
				</span>
				<span>{t("user.table.role")}:</span>
				<span>
					<Skeleton width="60%" />
				</span>
			</div>
			<div className="grid columns-label gapCol-medium gapRow-small aItems-center aContent-start">
				<span>{t("user.table.active")}:</span>
				<span>
					<Skeleton width="45%" />
				</span>
				<span>{t("user.table.status")}:</span>
				<span>
					<Skeleton width="85%" />
				</span>
				<span>{t("user.table.mfa")}:</span>
				<span>
					<Skeleton width="65%" />
				</span>
			</div>
			<div className="grid columns-label gapCol-medium gapRow-small aItems-center aContent-start">
				<span>{t("common.table.createdAt")}:</span>
				<span>
					<Skeleton width="75%" />
				</span>
				<span>{t("common.table.updatedAt")}:</span>
				<span>
					<Skeleton width="85%" />
				</span>
			</div>
		</div>
	);
};

export default UserDetailsSkeleton;
