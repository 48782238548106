//Node Modules
import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useRecoilValue, useResetRecoilState, useSetRecoilState, useRecoilRefresher_UNSTABLE } from "recoil";

//Pages

//BinaryForge Components
import { PageHeader } from "../../../components/layout";
import { ClinicCreateDialog, ClinicEditDialog, ClinicList, ClinicDetails } from "../../../components/clinic";

//3rd Party Components
import { Button } from "primereact/button";
import { TbBuildingHospital } from "react-icons/tb";

//Atoms
import { dialogAtomFamily } from "../../../atoms/dialog";
import { selectedClinicAtom, clinicListSelector, clinicListAtom } from "../../../atoms/clinic";

//Helpers
import { useHandleGenericExport } from "../../../helpers/Export";

//Other

const User = () => {
	const { t } = useTranslation();
	const handleFileExport = useHandleGenericExport();

	//Recoil
	const setShowCreateClinic = useSetRecoilState(dialogAtomFamily("clinicCreateDialog"));
	const clinics = useRecoilValue(clinicListAtom);
	const selectedClinic = useRecoilValue(selectedClinicAtom);
	const refreshClinicCache = useRecoilRefresher_UNSTABLE(clinicListSelector);
	const resetSelectedClinic = useResetRecoilState(selectedClinicAtom);

	useEffect(() => {
		refreshClinicCache();

		return () => {
			resetSelectedClinic();
		};
	}, []);

	const downloadClinicDetails = () => {
		const clinicDetails = clinics.map((c) => [
			c.name,
			c.buildingNumber,
			c.street,
			c.city,
			c.postcode,
			c.county,
			c.country,
		]);
		const exportData = {
			columns: [
				t("clinic.table.name"),
				t("clinic.table.building"),
				t("clinic.table.street"),
				t("clinic.table.city"),
				t("clinic.table.postcode"),
				t("clinic.table.county"),
				t("clinic.table.country"),
			],
			values: clinicDetails,
		};
		handleFileExport("csv", "Clinic Details", exportData);
	};

	const Actions = () => {
		return (
			<>
				<Button onClick={() => downloadClinicDetails()} label={t("clinic.actions.download")} />
				<Button
					onClick={() => setShowCreateClinic(true)}
					className="feature"
					label={t("clinic.actions.create")}
				/>
			</>
		);
	};

	return (
		<>
			<PageHeader
				title={t("clinic.pageTitle")}
				subtitle={t("clinic.pageSubtitle")}
				icon={<TbBuildingHospital />}
				actions={<Actions />}
			/>
			<main className="appMain">
				<div className="appMain-content">
					<section>
						<ClinicList />
					</section>
					{selectedClinic && <ClinicDetails />}
				</div>
			</main>

			{/* Dialogs */}
			<ClinicCreateDialog />
			<ClinicEditDialog />
		</>
	);
};

export default User;
