import { useRecoilValueLoadable } from "recoil";
import { firmwareUploadedBySelector } from "../../atoms/firmware";
import { Skeleton } from "primereact/skeleton";

const FirmwareUser = () => {
	//Recoil
	const { state: firmwareUserState, contents: firmwareUserData } = useRecoilValueLoadable(firmwareUploadedBySelector);

	switch (firmwareUserState) {
		case "hasError":
			//TODO: Show a proper error component here
			return <div>{firmwareUserData.message ? firmwareUserData.message : firmwareUserData}</div>;
		case "loading":
			return <Skeleton width="75%" />;
		case "hasValue":
			return <span>{firmwareUserData.fullname}</span>;
		default:
			return null;
	}
};

export default FirmwareUser;
