//Node Modules
import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";
import {
	useRecoilState,
	useRecoilValue,
	useResetRecoilState,
	useSetRecoilState,
	useRecoilRefresher_UNSTABLE,
} from "recoil";

//Pages

//BinaryForge Components
import { PageHeader } from "../../../components/layout";
import { UserList, UserDetails, UserCreateDialog, UserEditDialog } from "../../../components/user";
import { Note } from "../../../components/helpers";

//3rd Party Components
import { Button } from "primereact/button";
import { confirmDialog } from "primereact/confirmdialog";

//Atoms
import { dialogAtomFamily } from "../../../atoms/dialog";
import { selectedUserAtom, clinicianSelector, loggedInUserAtom, usersAtom, usersSelector } from "../../../atoms/user";
import { toastAtom } from "../../../atoms/toast";
import { clinicOptionsAtom } from "../../../atoms/clinic";

//Helpers
import { useApiRequest } from "../../../helpers/Api";
import { useHandleGenericExport } from "../../../helpers/Export";

//Other

const User = () => {
	const { t } = useTranslation();
	const apiRequest = useApiRequest();
	const handleFileExport = useHandleGenericExport();

	//Recoil
	const [toast, setToast] = useRecoilState(toastAtom);
	const setShowCreateUser = useSetRecoilState(dialogAtomFamily("userCreateDialog"));
	const setShowEditUser = useSetRecoilState(dialogAtomFamily("userEditDialog"));
	const loggedInUser = useRecoilValue(loggedInUserAtom);
	const clinicians = useRecoilValue(clinicianSelector);
	const setClinicOptions = useSetRecoilState(clinicOptionsAtom);
	const selectedUser = useRecoilValue(selectedUserAtom);
	const [users, setUsers] = useRecoilState(usersAtom);
	const refreshUserCache = useRecoilRefresher_UNSTABLE(usersSelector);
	const resetSelectedUser = useResetRecoilState(selectedUserAtom);

	useEffect(() => {
		refreshUserCache();
		getClinicOptions();

		return () => {
			resetSelectedUser();
		};
	}, []);

	const getClinicOptions = async () => {
		const clinics = await apiRequest("get", "/clinic?format=simple", null, null);
		setClinicOptions(clinics);
	};

	const handleResetPassword = () => {
		confirmDialog({
			header: t("user.passwordReset.header"),
			message: (
				<Note
					messageKey={t("user.passwordReset.message", {
						user: selectedUser.username,
						email: selectedUser.email,
					})}
					icon="pi pi-exclamation-triangle font-warn"
				/>
			),
			acceptLabel: t("user.passwordReset.accept"),
			rejectLabel: t("common.button.cancel"),
			acceptIcon: "pi pi-lock",
			acceptClassName: "feature",
			rejectClassName: "",
			accept: () => doResetPassword(),
			reject: () => {}, //Do Nothing,
		});
	};

	const doResetPassword = async () => {
		try {
			await apiRequest(
				"post",
				`/user/${selectedUser.username}/reset_password`,
				null,
				t("user.passwordReset.loading")
			);
			setToast({
				...toast,
				severity: "success",
				summary: t("user.passwordReset.toast.successSummary"),
				detail: t("user.passwordReset.toast.successDetail"),
			});
		} catch (err) {
			setToast({
				...toast,
				severity: "error",
				summary: t("user.passwordReset.toast.errorSummary"),
				detail: t("user.passwordReset.toast.errorDetail", {
					error: err.response ? err.response.data.message : err.message,
				}),
			});
		}
	};

	const handleResendInvite = () => {
		confirmDialog({
			header: t("user.resendInvite.header"),
			message: (
				<Note
					messageKey={t("user.resendInvite.message", {
						user: selectedUser.username,
						email: selectedUser.email,
					})}
					icon="pi pi-info-circle font-info"
				/>
			),
			acceptLabel: t("user.resendInvite.accept"),
			rejectLabel: t("common.button.cancel"),
			acceptIcon: "pi pi-lock",
			acceptClassName: "feature",
			rejectClassName: "",
			accept: () => doResendInvite(),
			reject: () => {}, //Do Nothing,
		});
	};

	const doResendInvite = async () => {
		try {
			await apiRequest("post", `/user/${selectedUser.username}/confirm`, null, t("user.resendInvite.loading"));
			setToast({
				...toast,
				severity: "success",
				summary: t("user.resendInvite.toast.successSummary"),
				detail: t("user.resendInvite.toast.successDetail"),
			});
		} catch (err) {
			setToast({
				...toast,
				severity: "error",
				summary: t("user.resendInvite.toast.errorSummary"),
				detail: t("user.resendInvite.toast.errorDetail", { error: err.message }),
			});
		}
	};

	const handleDeleteUser = () => {
		confirmDialog({
			header: t("user.deleteUser.header"),
			message: (
				<Note
					messageKey={t("user.deleteUser.message", {
						user: selectedUser.username,
					})}
					icon="pi pi-exclamation-triangle font-warn"
				/>
			),
			acceptLabel: t("user.deleteUser.accept"),
			rejectLabel: t("common.button.cancel"),
			acceptIcon: "pi pi-trash",
			acceptClassName: "error feature",
			rejectClassName: "",
			accept: () => doDeleteUser(),
			reject: () => {}, //Do Nothing,
		});
	};

	const doDeleteUser = async () => {
		try {
			await apiRequest("del", `/user/${selectedUser.username}`, null, t("user.deleteUser.loading"));
			setToast({
				...toast,
				severity: "success",
				summary: t("user.deleteUser.toast.successSummary"),
				detail: t("user.deleteUser.toast.successDetail", { user: selectedUser.username }),
			});
			const updatedUsers = users.filter((u) => u.username !== selectedUser.username);
			setUsers(updatedUsers);
			resetSelectedUser();
		} catch (err) {
			setToast({
				...toast,
				severity: "error",
				summary: t("user.deleteUser.toast.errorSummary"),
				detail: t("user.deleteUser.toast.errorDetail", { error: err.message }),
			});
		}
	};

	const downloadClinicianDetails = () => {
		const clinicianDetails = clinicians.map((c) => [c.fullname, c.email, c.active]);
		const exportData = {
			columns: [t("user.table.fullname"), t("user.table.email"), t("user.table.active")],
			values: clinicianDetails,
		};
		handleFileExport("csv", "Clinician Details", exportData);
	};

	const Actions = () => {
		return (
			<>
				<Button onClick={() => downloadClinicianDetails()} label={t("user.actions.clinicianDownload")} />
				<Button onClick={() => setShowCreateUser(true)} className="feature" label={t("user.actions.create")} />
			</>
		);
	};

	return (
		<>
			<PageHeader
				title={t("user.pageTitle")}
				subtitle={t("user.pageSubtitle")}
				icon={<i className="pi pi-users" />}
				actions={<Actions />}
			/>
			<main className="appMain">
				<div className="appMain-content">
					<section>
						<UserList />
					</section>
					{selectedUser && (
						<section>
							<div className="card">
								<div className="flex jContent-spaceBetween marginBottom-large">
									<div className="font-large">
										<span className="font-bold">{t("user.table.username")}: </span>
										<span>{selectedUser.username}</span>
									</div>
									<div className="flex gap-small jContent-end">
										<Button
											icon="pi pi-trash"
											onClick={() => handleDeleteUser()}
											disabled={!selectedUser || selectedUser.username === loggedInUser.username}
											tooltip={t("user.actions.deleteUser")}
											tooltipOptions={{ my: "center bottom-12", at: "center top" }}
										/>
										<Button
											icon="pi pi-lock"
											onClick={() => handleResetPassword()}
											disabled={!selectedUser || selectedUser.username === loggedInUser.username}
											tooltip={t("user.actions.resetPass")}
											tooltipOptions={{ my: "center bottom-12", at: "center top" }}
										/>
										<Button
											icon="pi pi-envelope"
											onClick={() => handleResendInvite()}
											disabled={!selectedUser || selectedUser.status !== "FORCE_CHANGE_PASSWORD"}
											tooltip={t("user.actions.resendInvite")}
											tooltipOptions={{ my: "center bottom-12", at: "center top" }}
										/>
										<Button
											onClick={() => setShowEditUser(true)}
											disabled={!selectedUser || selectedUser.username === loggedInUser.username}
											className="feature"
											label={t("user.actions.update")}
										/>
									</div>
								</div>
								<UserDetails />
							</div>
						</section>
					)}
				</div>
			</main>

			{/* Dialogs */}
			<UserCreateDialog />
			<UserEditDialog />
		</>
	);
};

export default User;
