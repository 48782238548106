import { useRecoilValueLoadable } from "recoil";
import { Skeleton } from "primereact/skeleton";
import { usernameSelectorFamily } from "../../atoms/user";

const Username = ({ sub }) => {
	//Recoil
	const { state: usernameState, contents: usernameData } = useRecoilValueLoadable(usernameSelectorFamily(sub));

	switch (usernameState) {
		case "hasError":
			//TODO: Show a proper error component here
			return <div>{usernameData.message ? usernameData.message : usernameData}</div>;
		case "loading":
			return <Skeleton width="75%" />;
		case "hasValue":
			return <span>{usernameData.fullname}</span>;
		default:
			return null;
	}
};

export default Username;
