import * as XLSX from "xlsx/xlsx.mjs";
import { useTranslation } from "react-i18next";
import { useRecoilState } from "recoil";

import { toastAtom } from "../atoms/toast";

import { formatDateTimeFile } from "./DateUtils";

import i18n from "../i18n";
const CSV_EXTENSION = ".csv";

const exportCsv = (columns, data, tableName) => {
	data.unshift(columns);
	const filename = `${i18n.t("common.appName")} - ${tableName} - ${formatDateTimeFile()}${CSV_EXTENSION}`;
	const workbook = XLSX.utils.book_new();
	const worksheet = XLSX.utils.aoa_to_sheet(data);
	XLSX.utils.book_append_sheet(workbook, worksheet, tableName);
	XLSX.writeFile(workbook, filename, { bookType: "csv" });
	// XLSX.writeFile(workbook, filename, { bookType: "xlsx" });
};

export const useHandleGenericExport = () => {
	const { t } = useTranslation();
	const [toast, setToast] = useRecoilState(toastAtom);

	const handleFileExport = (type, tableName, data) => {
		try {
			//Generate file
			exportCsv(data.columns, data.values, tableName);

			setToast({
				...toast,
				severity: "success",
				summary: t("common.toast.exportSuccessSummary"),
				detail: t("common.toast.exportSuccessDetail"),
			});
		} catch (error) {
			console.error("Error exporting file", error);

			setToast({
				...toast,
				severity: "error",
				summary: t("common.toast.exportErrorSummary"),
				detail: t("common.toast.exportErrorDetail"),
			});
		}
	};
	return handleFileExport;
};
