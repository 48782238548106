//Node Modules
import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { useForm, Controller } from "react-hook-form";

//Pages

//BinaryForge Components
import { PageHeader } from "../../../components/layout";
import { Code } from "../../../components/configuration";
import { Note } from "../../../components/helpers";

//3rd Party Components
import { InputText } from "primereact/inputtext";
import { Button } from "primereact/button";
import { classNames } from "primereact/utils";
import { ListBox } from "primereact/listbox";

//Atoms

//Helpers
import { useApiRequest } from "../../../helpers/Api";
import { getUiDateTime } from "../../../helpers/DateUtils";

//Other

const Configuration = () => {
	const { t } = useTranslation();
	const apiRequest = useApiRequest();

	//Local State
	const [selectedConfig, setSelectedConfig] = useState();
	const [configs, setConfigs] = useState();

	// Form Default Values
	const defaultValues = {
		serial: "ST-",
	};

	// Form Init
	const {
		control,
		getValues,
		formState: { errors },
		handleSubmit,
		reset,
	} = useForm({ defaultValues: defaultValues, mode: "onTouched", reValidateMode: "onChange" });

	// Form Error Message
	const getFormErrorMessage = (name) => {
		return errors[name] && <span className="font-error font-small">{errors[name].message}</span>;
	};

	const onSubmit = async (data) => {
		try {
			const configData = await apiRequest(
				"get",
				`/configuration?serial=${data.serial}&limit=5`,
				null,
				t("configuration.loading")
			);
			setConfigs(configData);
			setSelectedConfig(configData[0]);
		} catch (err) {}
	};

	const handleReset = () => {
		reset();
		setConfigs(null);
		setSelectedConfig(null);
	};

	const configItem = (option) => {
		return (
			<div className="configItem flex jContent-spaceBetween aItems-center gap-large">
				<div className="flex aSelf-stretch aItems-center gap-xsmall">
					<div className="selected"></div>
					<span>{getUiDateTime(option.updatedAt)}</span>
				</div>
				<div className="flexVert font-small font-light text-right paddingTop-small paddingBottom-small">
					<span>
						v{option.firmwareVersion} ({option.chipId.toUpperCase()})
					</span>
					<span>{option.product.name}</span>
				</div>
			</div>
		);
	};

	const buildConfig = () => {
		return {
			serial: selectedConfig.serial,
			configString: selectedConfig.configString,
			firmwareVersion: selectedConfig.firmwareVersion,
			product: selectedConfig.product.name,
			updatedAt: selectedConfig.updatedAt,
		};
	};

	return (
		<>
			<PageHeader
				title={t("configuration.pageTitle")}
				subtitle={t("configuration.pageSubtitle")}
				icon={<i className="pi pi-cloud-upload" />}
			/>
			<main className="appMain">
				<div className="appMain-content">
					<div className="paddingBottom-medium">
						<form onSubmit={handleSubmit(onSubmit)}>
							<div className="flex gap-medium aItems-center jContent-spaceBetween">
								<div className="formField">
									<label htmlFor="serial">{t("configuration.form.serial.label")}</label>
									<Controller
										name="serial"
										control={control}
										rules={{
											required: t("common.form.required"),
											pattern: {
												// value: /^(ST-)[A-Za-z0-9]{5,6}$/,
												value: /^(ST-)[0-9]{5,6}$/,
												message: t("configuration.form.serial.pattern"),
											},
										}}
										render={({ field, fieldState }) => (
											<InputText
												id={field.serial}
												{...field}
												size={32}
												className={classNames({ "p-error": fieldState.error })}
											/>
										)}
									/>
									{getFormErrorMessage("serial")}
								</div>
								<div className="flex gap-small">
									<Button type="submit" label={t("common.button.search")} className="feature" />
									<Button
										type="reset"
										label={t("common.button.reset")}
										onClick={() => handleReset()}
									/>
								</div>
							</div>
						</form>
					</div>
					{configs &&
						(configs.length > 0 ? (
							<div className="grid gap-xlarge columnsAutoFree-2">
								<section className="configItemWrapper">
									<header>
										<h3>{t("configuration.list.title")}</h3>
									</header>
									<ListBox
										value={selectedConfig}
										options={configs}
										onChange={(e) => setSelectedConfig(e.value)}
										itemTemplate={configItem}
									/>
								</section>
								{selectedConfig && <Code value={buildConfig()} json />}
							</div>
						) : (
							getValues("serial") && (
								<Note
									messageKey={t("configuration.notfound", { serial: getValues("serial") })}
									icon="pi pi-search font-info"
								/>
							)
						))}
				</div>
			</main>
		</>
	);
};

export default Configuration;
