//Node Modules
import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { Auth } from "aws-amplify";
import { useRecoilState, useSetRecoilState } from "recoil";

//BinaryForge Components
import { Note } from "../helpers";
import { UserSetupMFA } from "./";

//3rd Party Components
import { confirmDialog } from "primereact/confirmdialog";

//Atoms
import { dialogAtomFamily } from "../../atoms/dialog";
import { toastAtom } from "../../atoms/toast";

//Helpers
import { InputSwitch } from "primereact";

const UserProfileMFA = ({ currentUser }) => {
	// Hooks
	const { t } = useTranslation();

	//Recoil State
	const setShowMfaSetup = useSetRecoilState(dialogAtomFamily("userSetupMfaDialog"));
	const [toast, setToast] = useRecoilState(toastAtom);

	// Local State
	const [enabled, setEnabled] = useState(currentUser.preferredMFA === "SOFTWARE_TOKEN_MFA" ? true : false);

	const handleEnableMfa = (state) => {
		setEnabled(state);
		if (state) {
			setShowMfaSetup(true);
		} else {
			confirmDialog({
				header: t("profile.mfa.dialog.remove.header"),
				message: (
					<Note
						messageKey={t("profile.mfa.dialog.remove.message")}
						icon="pi pi-exclamation-triangle font-warn"
					/>
				),
				acceptLabel: t("profile.mfa.dialog.remove.disable"),
				rejectLabel: t("profile.mfa.dialog.remove.keep"),
				acceptIcon: "pi pi-lock-open",
				acceptClassName: "error feature",
				rejectClassName: "success",
				accept: () => doDisableMfa(),
				reject: () => {}, //Do Nothing,
			});
		}
	};

	const doDisableMfa = async () => {
		try {
			await Auth.setPreferredMFA(currentUser, "NOMFA");
			setToast({
				...toast,
				severity: "success",
				summary: t("profile.mfa.dialog.remove.toast.successSummary"),
				detail: t("profile.mfa.dialog.remove.toast.successDetail"),
			});
		} catch (err) {
			console.error("Disable MFA Error ::", err);
			setToast({
				...toast,
				severity: "error",
				summary: t("profile.mfa.dialog.remove.toast.errorSummary"),
				detail: t("profile.mfa.dialog.remove.toast.errorDetail", { error: err.message }),
			});
		}
	};

	return (
		<section>
			<div className="card">
				<header>
					<h3>{t("profile.mfa.title")}</h3>
				</header>
				<div className="flex aItems-center gap-medium marginBottom-small">
					<span className="label">{t("profile.mfa.enable")}:</span>
					<InputSwitch checked={enabled} onChange={(e) => handleEnableMfa(e.value)} />
				</div>
				<Note
					messageKey={t("profile.mfa.message")}
					icon="pi-info-circle font-info"
					messageStyle="font-light font-small"
				/>

				<UserSetupMFA currentUser={currentUser} />
			</div>
		</section>
	);
};

export default UserProfileMFA;
