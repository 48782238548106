//Node Modules
import { useState, useRef } from "react";
import { useRecoilState, useSetRecoilState, useRecoilValue } from "recoil";
import { useTranslation } from "react-i18next";
import { useForm, Controller } from "react-hook-form";
import { Storage } from "aws-amplify";
import _ from "lodash";

//BinaryForge Components
import { BfDialog } from "../helpers";
import { ItemTemplate, EmptyTemplate } from "../helpers";

//3rd Party Components
import { FileUpload } from "primereact/fileupload";
import { InputText } from "primereact/inputtext";
import { Button } from "primereact/button";
import { classNames } from "primereact/utils";
import { BsFileEarmarkZipFill } from "react-icons/bs";

//Atoms
import { dialogAtomFamily } from "../../atoms/dialog";
import { toastAtom } from "../../atoms/toast";
import { firmwareListAtom, firmwareCurrentStatusSelector } from "../../atoms/firmware";

//Helpers
import { useApiRequest } from "../../helpers/Api";
import { validateFirmwareFile, compareFirmwareVersions, getMD5 } from "../../helpers/Firmware";

//Other
import { firmwareStatusOptions } from "../../config/firmware";

const FirmwareUploadDialog = () => {
	// Hooks
	const { t } = useTranslation();
	const fileUploadRef = useRef(null);
	const apiRequest = useApiRequest();

	// Recoil
	const setLoading = useSetRecoilState(dialogAtomFamily("loadingDialog"));
	const setShow = useSetRecoilState(dialogAtomFamily("firmwareUploadDialog"));
	const [toast, setToast] = useRecoilState(toastAtom);
	const setFirmwares = useSetRecoilState(firmwareListAtom);
	const { public: currentPublic } = useRecoilValue(firmwareCurrentStatusSelector);

	// Local State
	const [file, setFile] = useState(null);

	// Form Default Values
	const defaultValues = {
		name: "",
		version: "0.01",
		active: false,
		status: firmwareStatusOptions[0].value,
	};

	// Form Init
	const {
		control,
		setValue,
		formState: { errors },
		handleSubmit,
		reset,
	} = useForm({ defaultValues, mode: "onTouched", reValidateMode: "onChange" });

	// Form Error Message
	const getFormErrorMessage = (name) => {
		return errors[name] && <span className="font-error font-small">{errors[name].message}</span>;
	};

	// Form Handle Submit
	const onSubmit = async (data) => {
		const productId = 1; //We only have myo kinisi so hardcode for now
		const md5Hash = getMD5(file);

		const uploadData = {
			...data,
			fileSize: file.size,
			filename: file.name,
			fileModified: file.lastModified,
			productId: productId,
			md5: md5Hash,
		};

		try {
			setLoading({ visible: true, message: t("firmware.dialog.upload.loading") });

			//Compare Firmware version to make sure the new version is higher
			if (!(await compareFirmwareVersions(currentPublic?.version, data.version)))
				throw Error("firmware.validation.version.old");

			//Validate the zip file to make sure it contains the expected files
			await validateFirmwareFile(file);

			//Upload the file to storage
			await Storage.put(`firmware/${productId}/${file.name}`, file, { level: "public" });

			//Create a record in the database
			const newFirmware = await apiRequest("post", "/firmware", uploadData, t("firmware.dialog.upload.loading"));

			setToast({
				...toast,
				severity: "success",
				summary: t("firmware.dialog.upload.toast.successSummary"),
				detail: t("firmware.dialog.upload.toast.successDetail", { filename: file.name, version: data.version }),
			});

			setFirmwares((prevState) =>
				_.unionBy(
					[
						{
							...newFirmware,
							uploadedAt: new Date(newFirmware.createdAt),
						},
					],
					prevState.map((s) => {
						const activeState = data.active ? false : s.active;
						return { ...s, active: activeState };
					}),
					"id"
				)
			);

			setShow(false);
			reset();
		} catch (err) {
			console.error("Upload Error ::", err);

			//TODO: If this fails to to an S3 issue we should tidy up the database and remove the entry
			//TODO: If this fails to add to the database we should tidy up S3

			setToast({
				...toast,
				severity: "error",
				summary: t("firmware.dialog.upload.toast.errorSummary"),
				detail: t("firmware.dialog.upload.toast.errorDetail", {
					error: err.response ? err.response.data.message : err.message,
				}),
			});
		} finally {
			setLoading({ visible: false });
		}
	};

	const handleFileSelect = async (event) => {
		const file = await event.files[0];
		setFile(file);

		const fileNameCheck = Number(file.name.split(".", 1)[0].split("-").join("."));
		const versionFromFile = file.name.split(".", 1)[0].split("-").join(".");
		if (!isNaN(fileNameCheck)) setValue("version", versionFromFile);
	};

	const handleFileClear = async () => {
		setValue("version", 0);
		setFile(null);
	};

	const itemTemplate = (file, props) => {
		return <ItemTemplate file={file} uploadProps={props} icon={<BsFileEarmarkZipFill />} />;
	};

	const emptyTemplate = () => {
		return <EmptyTemplate icon={<BsFileEarmarkZipFill />} message={t("firmware.dialog.upload.fileDrag")} />;
	};

	const doHide = () => {
		setShow(false);
		reset();
	};

	// Footer
	const footer = (
		<>
			<Button label={t("common.button.cancel")} onClick={() => doHide()} />
			<Button
				label={t("firmware.actions.upload")}
				icon="pi pi-save"
				className="feature"
				onClick={() => handleSubmit(onSubmit)()}
			/>
		</>
	);

	return (
		<BfDialog
			id="firmwareUploadDialog"
			header={t("firmware.dialog.upload.title")}
			footer={footer}
			dialogClass="firmwareUploadDialog">
			<FileUpload
				ref={fileUploadRef}
				accept=".zip"
				onSelect={handleFileSelect}
				onRemove={handleFileClear}
				onClear={handleFileClear}
				itemTemplate={itemTemplate}
				emptyTemplate={emptyTemplate}
				chooseLabel={t("firmware.dialog.upload.choose")}
				chooseOptions={{ icon: "pi pi-file" }}
				cancelLabel={t("firmware.dialog.upload.cancel")}
				cancelOptions={{ className: "error" }}
				uploadOptions={{ className: "hidden" }}
			/>
			<form className="marginTop-medium">
				<div className="grid columnsFreeAuto-2 gap-medium">
					<div className="formField">
						<label htmlFor="name">{t("firmware.firmwareForm.name.label")}</label>
						<Controller
							name="name"
							control={control}
							rules={{
								required: t("common.form.required"),
							}}
							render={({ field, fieldState }) => (
								<InputText
									id={field.name}
									{...field}
									className={classNames({ "p-error": fieldState.error })}
								/>
							)}
						/>
						{getFormErrorMessage("name")}
					</div>
					<div className="formField">
						<label htmlFor="version">{t("firmware.firmwareForm.version.label")}</label>
						<Controller
							name="version"
							control={control}
							rules={{
								required: t("common.form.required"),
								pattern: {
									value: /^[0-9]*\.[0-9]{2}$/,
									message: t("firmware.firmwareForm.version.pattern"),
								},
							}}
							render={({ field, fieldState }) => (
								<InputText
									id={field.name}
									{...field}
									className={classNames({ "p-error": fieldState.error })}
								/>
							)}
						/>
						{getFormErrorMessage("version")}
					</div>
				</div>
			</form>
		</BfDialog>
	);
};

export default FirmwareUploadDialog;
