//React
import React from "react";
import ReactDOM from "react-dom/client";
import reportWebVitals from "./reportWebVitals";

//Recoil
import { RecoilRoot } from "recoil";

//React Router
import { BrowserRouter as Router } from "react-router-dom";

//App
import App from "./App";
import { ErrorBoundary } from "./components/helpers";

//Amplify
import { Amplify } from "aws-amplify";
import { Auth } from "aws-amplify";
import awsExports from "./aws-exports";

//Sentry
import * as Sentry from "@sentry/react";
import { BrowserTracing } from "@sentry/tracing";

//Amplify Configure
Amplify.configure({
	...awsExports,
	API: {
		endpoints: [
			{
				name: "steeperApi",
				endpoint: process.env.REACT_APP_API_AWS_BASE + process.env.REACT_APP_API_AWS_STAGE,
				custom_header: async () => {
					return {
						Authorization: `${(await Auth.currentSession()).getIdToken().getJwtToken()}`,
						"Content-Type": "application/json",
					};
				},
			},
		],
	},
});

//Sentry Configure
Sentry.init({
	dsn: "https://93414acae4e047ddac994ddd22236943@o1334838.ingest.sentry.io/4504440974213120",
	integrations: [new BrowserTracing()],
	enableInExpoDevelopment: true,
	// Set tracesSampleRate to 1.0 to capture 100%
	// of transactions for performance monitoring.
	// We recommend adjusting this value in production
	tracesSampleRate: 1.0,
});

//App Structure
const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
	<React.StrictMode>
		<RecoilRoot>
			<Router>
				<Sentry.ErrorBoundary fallback={<ErrorBoundary />}>
					<App />
				</Sentry.ErrorBoundary>
			</Router>
		</RecoilRoot>
	</React.StrictMode>

	// <RecoilRoot>
	// 	<Router>
	// 		<App />
	// 	</Router>
	// </RecoilRoot>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
