//Node Modules
import { useEffect } from "react";
import { useRecoilState, useSetRecoilState, useRecoilValue } from "recoil";
import { useTranslation } from "react-i18next";
import { useForm, Controller } from "react-hook-form";
import _ from "lodash";

//BinaryForge Components

//3rd Party Components
import { InputText } from "primereact/inputtext";
import { Dropdown } from "primereact/dropdown";
import { MultiSelect } from "primereact/multiselect";
import { InputSwitch } from "primereact/inputswitch";
import { classNames } from "primereact/utils";

//Atoms
import { dialogAtomFamily } from "../../atoms/dialog";
import { selectedUserAtom, usersAtom } from "../../atoms/user";
import { triggerSubmitAtom } from "../../atoms/form";
import { toastAtom } from "../../atoms/toast";
import { clinicOptionsAtom } from "../../atoms/clinic";

//Helpers
import { useApiRequest } from "../../helpers/Api";

//Other
import { emailValidation, roleOptions, usernameValidation } from "../../config/user";
const appElement = document.getElementsByClassName("appWrapper")[0];

const UserForm = ({ type }) => {
	// Hooks
	const { t } = useTranslation();
	const apiRequest = useApiRequest();

	// Recoil
	const setShow = useSetRecoilState(dialogAtomFamily(`${type}Dialog`));
	const [toast, setToast] = useRecoilState(toastAtom);
	const [triggerSubmit, setTriggerSubmit] = useRecoilState(triggerSubmitAtom);
	const setUsers = useSetRecoilState(usersAtom);
	const [selectedUser, setSelectedUser] = useRecoilState(selectedUserAtom);
	const clinicOptions = useRecoilValue(clinicOptionsAtom);

	// Form Default Values
	const defaultValues = {
		username: "",
		fullname: "",
		email: "",
		emailConfirm: "",
		roles: [],
		active: true,
		testFirmwareAccess: false,
		clinic: null,
	};

	// Form Init
	const {
		control,
		getValues,
		watch,
		formState: { errors },
		handleSubmit,
		reset,
	} = useForm({ defaultValues: defaultValues, mode: "onTouched", reValidateMode: "onChange" });

	// Form Error Message
	const getFormErrorMessage = (name) => {
		return errors[name] && <span className="font-error font-small">{errors[name].message}</span>;
	};

	// Form Handle Submit
	useEffect(() => {
		if (triggerSubmit) {
			handleSubmit(onSubmit)();
			setTriggerSubmit(false);
		}
	}, [triggerSubmit]);

	// Fetch the user if one is available
	useEffect(() => {
		if (type === "userEdit" && selectedUser) {
			reset({
				username: selectedUser.username,
				fullname: selectedUser.fullname,
				email: selectedUser.email,
				emailConfirm: selectedUser.email,
				roles: selectedUser.roles,
				active: selectedUser.active,
				testFirmwareAccess: selectedUser.testFirmwareAccess,
				clinic: selectedUser.clinic,
			});
		}
	}, [selectedUser, type]);

	const onSubmit = async (data) => {
		try {
			const apiMethod = type === "userCreate" ? "post" : "put";
			const apiEndpoint = type === "userCreate" ? "/user" : `/user/${selectedUser.username}`;
			const user = await apiRequest(apiMethod, apiEndpoint, data, t(`user.${type}.loading`));
			setUsers((prevState) => _.orderBy(_.unionBy([user], prevState, "username"), ["username"], ["asc"]));
			type === "userEdit" && setSelectedUser(user);

			setToast({
				...toast,
				severity: "success",
				summary: t(`user.${type}.successSummary`),
				detail: t(`user.${type}.successDetail`, { username: data.username, email: data.email }),
			});
			setShow(false);
		} catch (err) {
			setToast({
				...toast,
				severity: "error",
				summary: t(`user.${type}.errorSummary`),
				detail: t(`user.${type}.errorDetail`, {
					error: err.response.data.message,
					data: JSON.stringify(data),
				}),
			});
		}
	};

	return (
		<form>
			<div className="grid columns-2 gap-medium">
				<div className="formField">
					<label htmlFor="username">{t("user.userForm.username.label")}</label>
					<Controller
						name="username"
						control={control}
						rules={{
							required: t("common.form.required"),
							pattern: { value: usernameValidation, message: t("user.userForm.username.pattern") },
						}}
						render={({ field, fieldState }) => (
							<InputText
								id={field.username}
								{...field}
								readOnly={type === "userCreate" ? false : true}
								className={classNames({ "p-error": fieldState.error })}
							/>
						)}
					/>
					{getFormErrorMessage("username")}
				</div>
				<div className="formField">
					<label htmlFor="fullname">{t("user.userForm.fullname.label")}</label>
					<Controller
						name="fullname"
						control={control}
						rules={{
							required: t("common.form.required"),
							pattern: { value: /^[A-Za-z\- ]+$/i, message: t("user.userForm.fullname.pattern") },
						}}
						render={({ field, fieldState }) => (
							<InputText
								id={field.fullname}
								{...field}
								// readOnly={type === "userCreate" ? false : true}
								className={classNames({ "p-error": fieldState.error })}
							/>
						)}
					/>
					{getFormErrorMessage("fullname")}
				</div>
				<div className="formField">
					<label htmlFor="email">{t("user.userForm.email.label")}</label>
					<Controller
						name="email"
						control={control}
						rules={{
							required: t("common.form.required"),
							pattern: {
								value: emailValidation,
								message: t("user.userForm.email.pattern"),
							},
						}}
						render={({ field, fieldState }) => (
							<InputText
								id={field.email}
								{...field}
								className={classNames({ "p-error": fieldState.error })}
							/>
						)}
					/>
					{getFormErrorMessage("email")}
				</div>
				<div className="formField">
					<label htmlFor="emailConfirm">{t("user.userForm.emailConfirm.label")}</label>
					<Controller
						name="emailConfirm"
						control={control}
						rules={{
							required: t("common.form.required"),
							pattern: {
								value: emailValidation,
								message: t("user.userForm.emailConfirm.pattern"),
							},
							validate: {
								match: (value) => getValues("email") === value || t("user.userForm.emailConfirm.match"),
							},
						}}
						render={({ field, fieldState }) => (
							<InputText
								id={field.emailConfirm}
								{...field}
								disabled={selectedUser && selectedUser.email === watch("email") && type === "userEdit"}
								className={classNames({ "p-error": fieldState.error })}
							/>
						)}
					/>
					{getFormErrorMessage("emailConfirm")}
				</div>
				<div className="formField">
					<label htmlFor="roles">{t("user.userForm.role.label")}</label>
					<Controller
						name="roles"
						control={control}
						rules={{ required: t("common.form.required") }}
						render={({ field: { ref, ...newField }, fieldState }) => (
							<MultiSelect
								appendTo={appElement}
								{...newField}
								inputRef={ref}
								options={roleOptions}
								placeholder={t("user.userForm.role.placeholder")}
								// itemTemplate={roleTemplate}
								// valueTemplate={roleTemplate}
								optionLabel="label"
								optionValue="value"
								onChange={(e) => newField.onChange(e.value)}
								className={classNames({ "p-error": fieldState.error })}
								scrollHeight="220px"
								panelHeaderTemplate={null}
							/>
						)}
					/>
					{getFormErrorMessage("role")}
				</div>
				<div className="grid columns-2 gap-medium">
					<div className="formField">
						<label htmlFor="testFirmwareAccess">{t("user.userForm.test.label")}</label>
						<Controller
							name="testFirmwareAccess"
							control={control}
							render={({ field, fieldState }) => (
								<InputSwitch
									id={field.active}
									{...field}
									checked={field.value}
									onChange={(e) => field.onChange(e.value)}
									className={classNames({ "p-error": fieldState.error })}
								/>
							)}
						/>
						{getFormErrorMessage("testFirmwareAccess")}
					</div>
					<div className="formField">
						<label htmlFor="active">{t("user.userForm.active.label")}</label>
						<Controller
							name="active"
							control={control}
							render={({ field, fieldState }) => (
								<InputSwitch
									id={field.active}
									{...field}
									checked={field.value}
									onChange={(e) => field.onChange(e.value)}
									className={classNames({ "p-error": fieldState.error })}
								/>
							)}
						/>
						{getFormErrorMessage("active")}
					</div>
				</div>

				{watch("roles").includes("clinician") && (
					<div className="formField">
						<label htmlFor="clinic">{t("user.userForm.clinic.label")}</label>
						<Controller
							name="clinic"
							control={control}
							rules={{ required: t("common.form.required") }}
							render={({ field: { ref, ...newField }, fieldState }) => (
								<Dropdown
									appendTo={appElement}
									{...newField}
									inputRef={ref}
									options={clinicOptions}
									placeholder={t("user.userForm.clinic.placeholder")}
									optionLabel="name"
									optionValue="id"
									onChange={(e) => newField.onChange(e.value)}
									className={classNames({ "p-error": fieldState.error })}
								/>
							)}
						/>
						{getFormErrorMessage("clinic")}
					</div>
				)}
			</div>
		</form>
	);
};

export default UserForm;
