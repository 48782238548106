//Node Modules
import React from "react";
import { useTranslation } from "react-i18next";
import { useForm, Controller } from "react-hook-form";
import { Auth } from "aws-amplify";
import { useNavigate, Link } from "react-router-dom";
import { useRecoilState } from "recoil";

//Pages

//BinaryForge Components
import logo from "../../assets/img/steeper-logo-en-GB.svg";

//3rd Party Components
import { Button } from "primereact/button";
import { InputText } from "primereact/inputtext";
import { Password } from "primereact/password";
import { classNames } from "primereact/utils";

//Atoms
import { toastAtom } from "../../atoms/toast";
import { loggedInUserAtom } from "../../atoms/user";

//Helpers
import { nav } from "../../config/navigation";
import { useSetRecoilState } from "recoil";

//Other

function Login() {
	const { t } = useTranslation();
	const navigate = useNavigate();

	//Recoil Atom States
	const [toast, setToast] = useRecoilState(toastAtom);
	const setLoginUser = useSetRecoilState(loggedInUserAtom);

	//React Hook Form
	const defaultValues = { username: "", password: "" };
	const {
		control,
		formState: { errors, isSubmitting },
		handleSubmit,
	} = useForm({
		defaultValues,
		mode: "onTouched",
		reValidateMode: "onChange",
	});

	// Form Error Message
	const getFormErrorMessage = (name) => {
		return errors[name] && <span className="font-error font-small">{errors[name].message}</span>;
	};

	// Login Form Submit
	const onLogin = async (data) => {
		let toPage = nav.guest.logout;
		const lazyUsername = data.username.trim(); //Bodge fix because copy and paste username can include whitespace - I don't like this.
		try {
			const response = await Auth.signIn(lazyUsername, data.password);

			if (response.challengeName === "NEW_PASSWORD_REQUIRED") {
				setLoginUser({ ...data, roles: [] });
				toPage = nav.guest.completeReg;
			} else if (response.challengeName === "SOFTWARE_TOKEN_MFA") {
				setLoginUser({ ...data, roles: [] });
				toPage = nav.guest.mfa;
			} else {
				if (response.signInUserSession.idToken.payload["cognito:groups"]) {
					const roles = response.signInUserSession.idToken.payload["cognito:groups"];
					if (roles.includes("clinician")) {
						toPage = nav.guest.mobileApp;
						throw Error(t("login.error.roleNoAccess", { role: t("user.role.clinician") }));
					} else {
						setLoginUser({ username: lazyUsername, roles: roles });
						toPage = nav.home;
					}
				} else {
					throw Error(t("login.error.noGroup"));
				}
			}
		} catch (err) {
			console.error("onSubmit Error:", err);
			let errorMsg = "";
			if (err.message.includes("PreAuthentication")) {
				errorMsg = err.message.substring(36);
			} else {
				errorMsg = err.message;
			}
			setToast({
				...toast,
				severity: "error",
				summary: t("login.toast.loginFail.summary"),
				detail: t("login.toast.loginFail.detail", { error: errorMsg }),
			});
		} finally {
			navigate(toPage);
		}
	};

	return (
		<main className="appMain">
			<div className="appMain-content">
				<img src={logo} alt="Logo" className="logo marginBottom-small" />
				<div className="appName marginBottom-xlarge text-center">
					<span className="font-large font-lighter text-uppercase">{t("common.appName")}</span>
				</div>

				<form onSubmit={handleSubmit(onLogin)}>
					<div className="formFieldsWrapper">
						<div className="formField">
							<label htmlFor="username">{t("login.username.label")}</label>
							<Controller
								name="username"
								control={control}
								rules={{ required: t("login.username.required") }}
								render={({ field, fieldState }) => (
									<InputText
										id={field.username}
										{...field}
										className={classNames({ "p-error": fieldState.error })}
									/>
								)}
							/>
							{getFormErrorMessage("username")}
						</div>
						<div className="formField">
							<label htmlFor="password">{t("login.password.label")}</label>
							<Controller
								name="password"
								control={control}
								rules={{ required: t("login.password.required") }}
								render={({ field, fieldState }) => (
									<Password
										id={field.password}
										{...field}
										feedback={false}
										toggleMask={true}
										className={classNames({ "p-error": fieldState.error })}
									/>
								)}
							/>
							{getFormErrorMessage("password")}
						</div>
					</div>

					<Button
						label={isSubmitting ? t("login.submitting") : t("login.submit")}
						type="submit"
						className="feature"
						disabled={isSubmitting}
					/>
				</form>
				<div className="marginTop-small text-center">
					<Link to={nav.guest.forgotPassword} className="font-small">
						{t("login.forgotPassword")}
					</Link>
				</div>
			</div>
		</main>
	);
}

export default Login;
