//Node Modules
import { useState, useRef, useEffect } from "react";
import { useRecoilState } from "recoil";
import { useTranslation } from "react-i18next";

//Pages

//BinaryForge Components

//3rd Party Components
import { FilterMatchMode, FilterOperator } from "primereact/api";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { Button } from "primereact/button";

//Atoms
import { selectedClinicAtom, clinicListAtom } from "../../atoms/clinic";

//Helpers

//Other

const ClinicListTable = ({ clinicData }) => {
	//Hooks
	const { t } = useTranslation();
	const dt = useRef(null);

	//Recoil
	const [selectedClinic, setSelectedClinic] = useRecoilState(selectedClinicAtom);
	const [clinics, setClinics] = useRecoilState(clinicListAtom);

	// Local State
	const [dtFilters, setDtFilters] = useState(null);

	const initFilters = () => {
		setDtFilters({
			name: {
				operator: FilterOperator.AND,
				constraints: [{ value: null, matchMode: FilterMatchMode.CONTAINS }],
			},
			buildingNumber: {
				operator: FilterOperator.AND,
				constraints: [{ value: null, matchMode: FilterMatchMode.CONTAINS }],
			},
			street: {
				operator: FilterOperator.AND,
				constraints: [{ value: null, matchMode: FilterMatchMode.CONTAINS }],
			},
			city: {
				operator: FilterOperator.AND,
				constraints: [{ value: null, matchMode: FilterMatchMode.CONTAINS }],
			},
			postcode: {
				operator: FilterOperator.AND,
				constraints: [{ value: null, matchMode: FilterMatchMode.CONTAINS }],
			},
			county: {
				operator: FilterOperator.AND,
				constraints: [{ value: null, matchMode: FilterMatchMode.CONTAINS }],
			},
			country: {
				operator: FilterOperator.AND,
				constraints: [{ value: null, matchMode: FilterMatchMode.CONTAINS }],
			},
		});
	};

	const filterApplyTemplate = (options) => {
		return (
			<Button
				type="button"
				label="Filter"
				icon="pi pi-check"
				onClick={options.filterApplyCallback}
				className="feature"
			/>
		);
	};

	useEffect(() => {
		if (clinicData) {
			setClinics(clinicData);
			initFilters();
		}
	}, [clinicData]);

	return (
		<DataTable
			ref={dt}
			value={clinics}
			emptyMessage={t("common.table.noData")}
			selectionMode="single"
			selection={selectedClinic}
			onSelectionChange={(e) => setSelectedClinic(e.value)}
			sortMode="multiple"
			removableSort
			filters={dtFilters}
			filterDisplay="menu"
			autoLayout={true}
			paginator
			paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport"
			currentPageReportTemplate={t("common.table.paginatorTemplate")}
			rows={10}
			dataKey="id">
			<Column field="name" header={t("clinic.table.name")} sortable filter filterApply={filterApplyTemplate} />
			<Column
				field="buildingNumber"
				header={t("clinic.table.building")}
				sortable
				filter
				filterApply={filterApplyTemplate}
			/>
			<Column
				field="street"
				header={t("clinic.table.street")}
				sortable
				filter
				filterApply={filterApplyTemplate}
			/>
			<Column field="city" header={t("clinic.table.city")} sortable filter filterApply={filterApplyTemplate} />
			<Column
				field="postcode"
				header={t("clinic.table.postcode")}
				sortable
				filter
				filterApply={filterApplyTemplate}
			/>
			<Column
				field="county"
				header={t("clinic.table.county")}
				sortable
				filter
				filterApply={filterApplyTemplate}
			/>
			<Column
				field="country"
				header={t("clinic.table.country")}
				sortable
				filter
				filterApply={filterApplyTemplate}
			/>
		</DataTable>
	);
};

export default ClinicListTable;
