//Node Modules
import { useState, useEffect } from "react";
import { useRecoilValue, useSetRecoilState } from "recoil";
import { useTranslation } from "react-i18next";
import { useForm, Controller } from "react-hook-form";
import _ from "lodash";

//BinaryForge Components
import { BfDialog } from "../helpers";
import { Note } from "../helpers";

//3rd Party Components
import { Button } from "primereact/button";
import { Dropdown } from "primereact/dropdown";
import { InputTextarea } from "primereact/inputtextarea";
import { classNames } from "primereact/utils";

//Atoms
import { dialogAtomFamily } from "../../atoms/dialog";

//Helpers
import { useApiRequest } from "../../helpers/Api";

//Other
import { getAvailableFirmwareStatusOptions } from "../../config/firmware";
import { firmwareCurrentStatusSelector, firmwareListAtom, selectedFirmwareAtom } from "../../atoms/firmware";
import { compareFirmwareVersions } from "../../helpers/Firmware";
const appElement = document.getElementsByClassName("appWrapper")[0];

const FirmwareStatusDialog = ({ selectedFirmware }) => {
	// Hooks
	const { t } = useTranslation();
	const apiRequest = useApiRequest();

	// LocalState
	const [testValid, setTestValid] = useState(false);
	const [publicValid, setPublicValid] = useState(false);

	// Recoil
	const setShow = useSetRecoilState(dialogAtomFamily("firmwareStatusDialog"));
	const currentStatus = useRecoilValue(firmwareCurrentStatusSelector);
	const setSelectedFirmware = useSetRecoilState(selectedFirmwareAtom);
	const setFirmwares = useSetRecoilState(firmwareListAtom);

	// Form Default Values
	const defaultValues = {
		status: selectedFirmware.status,
		comment: "",
		currentTestStatus: null,
		currentTestComment: "",
		currentPublicStatus: null,
		currentPublicComment: "",
	};

	// Form Init
	const {
		control,
		watch,
		formState: { errors },
		handleSubmit,
		reset,
	} = useForm({ defaultValues, mode: "onTouched", reValidateMode: "onChange" });

	// Form Error Message
	const getFormErrorMessage = (name) => {
		return errors[name] && <span className="font-error font-small">{errors[name].message}</span>;
	};

	// Form Handle Submit
	const onSubmit = async (data) => {
		const body = {
			...data,
			currentTestId: currentStatus.test?.id,
			currentPublicId: currentStatus.public?.id,
		};
		try {
			const updatedFirmware = await apiRequest(
				"put",
				`/firmware/${selectedFirmware.id}`,
				body,
				t("firmware.dialog.status.loading")
			);

			updatedFirmware.uploadedAt = new Date(updatedFirmware.createdAt);
			setSelectedFirmware(updatedFirmware);

			const updatedFirmwares = [updatedFirmware];

			if (data.currentTestStatus)
				updatedFirmwares.push({ ...currentStatus.test, status: data.currentTestStatus });
			if (data.currentPublicStatus)
				updatedFirmwares.push({ ...currentStatus.public, status: data.currentPublicStatus });

			setFirmwares((prevState) =>
				_.orderBy(_.unionBy(updatedFirmwares, prevState, "id"), ["uploadedAt"], ["desc"])
			);

			resetAndHide();
		} catch (err) {
			console.error("Error ::", err.message);
		}
	};

	const getTestValid = async () => {
		setTestValid(await compareFirmwareVersions(selectedFirmware.version, currentStatus.test?.version));
	};

	const getPublicValid = async () => {
		setPublicValid(await compareFirmwareVersions(selectedFirmware.version, currentStatus.test?.version));
	};

	//On Dialog Load
	useEffect(() => {
		getTestValid();
		getPublicValid();
	}, [selectedFirmware]);

	const resetAndHide = () => {
		setShow(false);
		reset();
	};

	// Footer
	const footer = (
		<>
			<Button label={t("common.button.cancel")} onClick={() => resetAndHide()} />
			<Button
				label={t("common.button.update")}
				className="feature"
				onClick={() => handleSubmit(onSubmit)()}
				disabled={watch("status") === selectedFirmware.status}
			/>
		</>
	);

	return (
		<BfDialog id="firmwareStatusDialog" header={t("firmware.dialog.status.title")} footer={footer}>
			<form>
				<div className="formField">
					<label htmlFor="status">
						{t("firmware.dialog.status.label", { selected: selectedFirmware.name })}
					</label>
					<Controller
						name="status"
						control={control}
						rules={{ required: t("common.form.required") }}
						render={({ field: { ref, ...newField }, fieldState }) => (
							<Dropdown
								appendTo={appElement}
								{...newField}
								inputRef={ref}
								options={getAvailableFirmwareStatusOptions(selectedFirmware.status)}
								placeholder={t("firmware.firmwareForm.status.placeholder")}
								onChange={(e) => newField.onChange(e.value)}
								className={classNames({ "p-error": fieldState.error })}
							/>
						)}
					/>
					{getFormErrorMessage("status")}
				</div>
				{watch("status") !== selectedFirmware.status && watch("status") > 2 && (
					<div className="formField">
						<div className="flex jContent-spaceBetween">
							<label htmlFor="comment">{t("common.form.comment.label")}</label>
							<span className="font-lighter font-small">{watch("comment").length}/255</span>
						</div>
						<Controller
							name="comment"
							control={control}
							rules={{
								required: t("common.form.required"),
								maxLength: {
									value: 255,
									message: t("common.form.comment.maxLength", { length: "255" }),
								},
							}}
							render={({ field, fieldState }) => (
								<InputTextarea
									id={field.name}
									{...field}
									placeholder={t("firmware.dialog.status.comment.placeholder", {
										status: watch("status"),
									})}
									autoResize={true}
									className={classNames({ "p-error": fieldState.error })}
								/>
							)}
						/>
						{getFormErrorMessage("comment")}
					</div>
				)}
				{selectedFirmware.status !== 1 && watch("status") === 1 && (
					<div>
						{currentStatus.test?.id && (
							<>
								<Note
									icon="pi-exclamation-triangle font-warn"
									messageKey={t("firmware.dialog.status.test.current.singleWarn", {
										current: currentStatus.test,
									})}
									messageStyle="font-small font-light"
								/>
								{testValid && (
									<Note
										icon="pi-exclamation-triangle font-error"
										messageKey={t("firmware.dialog.status.test.current.higherWarn", {
											current: currentStatus.test,
										})}
										messageStyle="font-small font-light"
										wrapperStyle="marginTop-small"
									/>
								)}
								<div className="formField marginTop-medium">
									<label htmlFor="currentTestStatus">
										{t("firmware.dialog.status.current.select.label", {
											current: currentStatus.test.name,
										})}
									</label>
									<Controller
										name="currentTestStatus"
										control={control}
										rules={{ required: t("common.form.required") }}
										render={({ field: { ref, ...newField }, fieldState }) => (
											<Dropdown
												appendTo={appElement}
												{...newField}
												inputRef={ref}
												options={
													testValid
														? getAvailableFirmwareStatusOptions(4)
														: getAvailableFirmwareStatusOptions(5)
												}
												placeholder={t("firmware.dialog.status.current.select.placeholder", {
													current: "Test",
												})}
												onChange={(e) => newField.onChange(e.value)}
												className={classNames({ "p-error": fieldState.error })}
											/>
										)}
									/>
									{getFormErrorMessage("currentTestStatus")}
								</div>
								<div className="formField marginTop-small">
									<div className="flex jContent-spaceBetween">
										<label htmlFor="currentTestComment">{t("common.form.comment.label")}</label>
										<span className="font-lighter font-small">
											{watch("currentTestComment").length}/255
										</span>
									</div>
									<Controller
										name="currentTestComment"
										control={control}
										rules={{
											required: t("common.form.required"),
											maxLength: {
												value: 255,
												message: t("common.form.comment.maxLength", { length: "255" }),
											},
										}}
										render={({ field, fieldState }) => (
											<InputTextarea
												id={field.name}
												{...field}
												placeholder={
													watch("currentTestStatus")
														? t("firmware.dialog.status.current.comment.placeholder", {
																status: watch("currentTestStatus"),
														  })
														: null
												}
												autoResize={true}
												className={classNames({ "p-error": fieldState.error })}
											/>
										)}
									/>
									{getFormErrorMessage("currentTestcomment")}
								</div>
								{currentStatus.public?.id && (
									<Note
										icon="pi-info-circle font-info"
										messageKey={t("firmware.dialog.status.test.current.publicInfo", {
											current: currentStatus.test,
										})}
										messageStyle="font-small font-light"
										wrapperStyle="marginTop-small"
									/>
								)}
							</>
						)}
					</div>
				)}

				{selectedFirmware.status !== 2 && watch("status") === 2 && (
					<div>
						{currentStatus.public?.id && (
							<>
								<Note
									icon="pi-exclamation-triangle font-warn"
									messageKey={t("firmware.dialog.status.public.current.singleWarn", {
										current: currentStatus.public,
									})}
									messageStyle="font-small font-light"
								/>
								{publicValid && (
									<Note
										icon="pi-exclamation-triangle font-error"
										messageKey={t("firmware.dialog.status.public.current.higherWarn", {
											current: currentStatus.public,
										})}
										messageStyle="font-small font-light"
										wrapperStyle="marginTop-small"
									/>
								)}
								<div className="formField marginTop-medium">
									<label htmlFor="currentPublicStatus">
										{t("firmware.dialog.status.current.select.label", {
											current: currentStatus.public.name,
										})}
									</label>
									<Controller
										name="currentPublicStatus"
										control={control}
										rules={{ required: t("common.form.required") }}
										render={({ field: { ref, ...newField }, fieldState }) => (
											<Dropdown
												appendTo={appElement}
												{...newField}
												inputRef={ref}
												options={
													publicValid
														? getAvailableFirmwareStatusOptions(4)
														: getAvailableFirmwareStatusOptions(5)
												}
												placeholder={t("firmware.dialog.status.current.select.placeholder", {
													current: "Public",
												})}
												onChange={(e) => newField.onChange(e.value)}
												className={classNames({ "p-error": fieldState.error })}
											/>
										)}
									/>
									{getFormErrorMessage("currentPublicStatus")}
								</div>
								<div className="formField marginTop-small">
									<div className="flex jContent-spaceBetween">
										<label htmlFor="currentPublicComment">{t("common.form.comment.label")}</label>
										<span className="font-lighter font-small">
											{watch("currentPublicComment").length}/255
										</span>
									</div>
									<Controller
										name="currentPublicComment"
										control={control}
										rules={{
											required: t("common.form.required"),
											maxLength: {
												value: 255,
												message: t("common.form.comment.maxLength", { length: "255" }),
											},
										}}
										render={({ field, fieldState }) => (
											<InputTextarea
												id={field.name}
												{...field}
												placeholder={
													watch("currentPublicStatus")
														? t("firmware.dialog.status.current.comment.placeholder", {
																status: watch("currentPublicStatus"),
														  })
														: null
												}
												autoResize={true}
												className={classNames({ "p-error": fieldState.error })}
											/>
										)}
									/>
									{getFormErrorMessage("currentPublicComment")}
								</div>
							</>
						)}
					</div>
				)}
			</form>
		</BfDialog>
	);
};

export default FirmwareStatusDialog;
