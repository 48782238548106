import { useTranslation } from "react-i18next";

const Or = ({ orientation }) => {
	const { t } = useTranslation();

	return (
		<div className={`orWrapper ${orientation}`}>
			<div className="orLine"></div>
			<span className="orMsg">{t("common.or")}</span>
		</div>
	);
};

export default Or;
