//Node Modules
import { useRecoilValueLoadable } from "recoil";
import { useTranslation } from "react-i18next";

//Pages

//BinaryForge Components
import { ClinicListTable } from "./";
import { Note } from "../helpers";

//3rd Party Components

//Atoms
import { clinicListSelector } from "../../atoms/clinic";
import { DatatableSkeleton } from "../helpers/skeleton";

//Helpers

//Other

const ClinicList = () => {
	//Recoil
	const { t } = useTranslation();
	const { state, contents } = useRecoilValueLoadable(clinicListSelector);

	switch (state) {
		case "hasError":
			return (
				<Note
					messageKey={`Error: ${contents.message ? contents.message : contents}`}
					icon="pi-exclamation-circle font-error"
				/>
			);
		case "loading":
			return (
				<DatatableSkeleton
					columns={[
						t("clinic.table.name"),
						t("clinic.table.building"),
						t("clinic.table.street"),
						t("clinic.table.postcode"),
						t("clinic.table.county"),
						t("clinic.table.country"),
					]}
				/>
			);
		case "hasValue":
			return <ClinicListTable clinicData={contents} />;
		default:
			return null;
	}
};

export default ClinicList;
