//Node Modules
import React from "react";
import { useTranslation } from "react-i18next";
import { useForm, Controller } from "react-hook-form";
import { Auth } from "aws-amplify";
import { Link, useNavigate } from "react-router-dom";
import { useRecoilState } from "recoil";

//Pages

//BinaryForge Components
import logo from "../../assets/img/steeper-logo-en-GB.svg";

//3rd Party Components
import { Button } from "primereact/button";
import { InputText } from "primereact/inputtext";
import { classNames } from "primereact/utils";

//Atoms
import { loggedInUserAtom } from "../../atoms/user";
import { toastAtom } from "../../atoms/toast";

//Helpers
import { nav } from "../../config/navigation";
import { useLogout } from "../../helpers/Auth";

//Other

function MfaChallenge() {
	const { t } = useTranslation();
	const navigate = useNavigate();
	const handleLogout = useLogout();

	// Recoil State
	const [userData, setUserData] = useRecoilState(loggedInUserAtom);
	const [toast, setToast] = useRecoilState(toastAtom);

	//React Hook Form
	const defaultValues = {
		mfaCode: "",
	};
	const {
		control,
		formState: { errors, isSubmitting },
		handleSubmit,
	} = useForm({
		defaultValues,
		mode: "onTouched",
		reValidateMode: "onChange",
	});

	// Form Error Message
	const getFormErrorMessage = (name) => {
		return errors[name] && <span className="font-error font-small">{errors[name].message}</span>;
	};

	// Login Form Submit
	const onMfaLogin = async (data) => {
		let toPage = nav.guest.logout;
		try {
			const cognitoUser = await Auth.signIn(userData.username, userData.password);
			const response = await Auth.confirmSignIn(cognitoUser, data.mfaCode, "SOFTWARE_TOKEN_MFA");

			const roles = response.signInUserSession.idToken.payload["cognito:groups"];
			if (roles.includes("clinician")) {
				toPage = nav.guest.mobileApp;
				throw Error(t("login.error.roleNoAccess", { role: t(`user.role.clinician`) }));
			} else {
				setUserData((prevState) => ({ username: prevState.username, roles: roles }));
				toPage = nav.home;
			}
		} catch (err) {
			console.error("onSubmit Error:", err);
			setToast({
				...toast,
				severity: "error",
				summary: t("login.toast.loginFail.summary"),
				detail: t("login.toast.loginFail.detail", { error: err.message }),
			});
			if (!err.message.includes("Clinician")) {
				handleLogout();
			}
		} finally {
			navigate(toPage);
		}
	};

	return (
		<main className="appMain">
			<div className="appMain-content">
				<img src={logo} alt="Logo" className="logo marginBottomSmall" />
				<div className="appName marginBottom-xlarge text-center">
					<span className="font-large font-lighter text-uppercase">{t("common.appName")}</span>
				</div>
				<form onSubmit={handleSubmit(onMfaLogin)}>
					<div className="formFieldsWrapper">
						<div className="formField">
							<label htmlFor="mfaCode">{t("login.mfaCode.label")}</label>
							<Controller
								name="mfaCode"
								control={control}
								rules={{ required: t("common.form.required") }}
								render={({ field, fieldState }) => (
									<InputText
										id={field.mfaCode}
										{...field}
										className={classNames({ "p-error": fieldState.error })}
									/>
								)}
							/>
							{getFormErrorMessage("mfaCode")}
						</div>
					</div>

					<Button
						label={isSubmitting ? t("login.submitting") : t("login.submit")}
						type="submit"
						className="feature"
						disabled={isSubmitting}
					/>
				</form>
				<div className="marginTop-small text-center">
					<Link to={nav.guest.login} className="font-small">
						{t("forgotPassword.back")}
					</Link>
				</div>
			</div>
		</main>
	);
}

export default MfaChallenge;
