//Node Modules
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import { useRecoilState } from "recoil";

//Pages

//BinaryForge Components
import logo from "../../assets/img/steeper-logo-en-GB.svg";
import googlePlay from "../../assets/img/GetItOnGooglePlay_Badge_Web_color_English.png";
import appleStore from "../../assets/img/Download_on_the_App_Store_Badge_US-UK.svg";
import googleQR from "../../assets/img/googleQR.svg";
import appleQR from "../../assets/img/appleQR.svg";

//3rd Party Components

//Atoms
import { toastAtom } from "../../atoms/toast";

//Helpers

//Other
import { nav } from "../../config/navigation";

const MobileApp = () => {
	const { t } = useTranslation();

	return (
		<main className="appMain">
			<div className="appMain-content">
				<img src={logo} alt="Logo" className="logo marginBottomSmall" />
				<div className="appName marginBottom-xlarge text-center">
					<span className="font-large font-lighter text-uppercase">{t("common.appName")}</span>
				</div>

				<div>
					<p className="font-large font-bold">{t("mobileApp.message1")}</p>
					<p className="font-bold">{t("mobileApp.message2")}</p>
					<div className="grid columns-2 gap-medium jContent-spaceBetween marginTop-medium">
						<a
							href="https://play.google.com/store/apps/details?id=io.binaryforge.myokinisi&hl=en_GB"
							target="_blank"
							className="flexVert aItems-center gap-xsmall">
							<span>{t("mobileApp.android")}</span>
							<img src={googlePlay} alt="Get it on Google Play" style={{ height: "60px" }} />
							<img
								src={googleQR}
								alt="QR Code for Google Play"
								className="marginTop-small"
								style={{ width: "100%" }}
							/>
						</a>

						<a
							href="https://apps.apple.com/us/app/steeper-myo-kinisi/id6444189529"
							target="_blank"
							className="flexVert aItems-center gap-xsmall">
							<span>{t("mobileApp.apple")}</span>
							<img src={appleStore} alt="Download on Apple App Store" style={{ height: "60px" }} />
							<img
								src={appleQR}
								alt="QR Code for Apple App Store"
								className="marginTop-small"
								style={{ width: "100%" }}
							/>
						</a>
					</div>
				</div>

				<div className="marginTop-small text-center">
					<Link to={nav.guest.login} className="font-small">
						{t("forgotPassword.back")}
					</Link>
				</div>
			</div>
		</main>
	);
};

export default MobileApp;
